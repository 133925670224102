import React from 'react';
import styled from 'styled-components'
import { ShippingType } from '../../../../setup/types/request-data-types/RequestDataTypes'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ButtonSecondary, CustomHeader } from '../../../components/CustomStyledComponents'
import { StoreSettingsTabs } from './StoreSettings'

type Props = {
  className: string
  data: ShippingType | undefined
  changeTo: (tab: string) => void
}

const ShippingTable: React.FC<Props> = ({ className, data, changeTo }) => {
  function getRows() {
    return data?.storeShippingStepItems?.map((item, index) => (
      <tr key={index}>
        <td className='px-5'>
          <div className='d-flex align-items-center'>
            <div className='w-50'>
              <span className='fw-bold '>FROM</span>
              <span className='ms-5'>{`${item?.purchasePriceFrom || 0} ${data?.currency}`}</span>
            </div>
            <div className='w-50'>
              <span className='fw-bold'>TO</span>
              <span className='ms-5'>{`${item?.purchasePriceTo || 0} ${data?.currency}`}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <div className='w-50'>
              <span className='fw-bold '>FROM</span>
              <span className='ms-5'>{`${item?.weightFrom || 0}`}</span>
            </div>
            <div className='w-50'>
              <span className='fw-bold'>TO</span>
              <span className='ms-5'>{`${item?.weightTo || 0}`}</span>
            </div>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <TdContent className='d-flex justify-content-start input-group'>
              <span>{`${item?.shippingPerItem || 0} ${data?.currency}`}</span>
            </TdContent>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <TdContent className='d-flex justify-content-start input-group'>
              <span>{`${item?.shippingPerOunce || 0} ${data?.currency}`}</span>
            </TdContent>
          </div>
        </td>
      </tr>
    ))
  }

  return (
    <Container className={`card ${className}`}>
      <CustomHeader fontSize={18} marginBlock={12}>
        SHIPPING PRICING SETTINGS{' '}
      </CustomHeader>
      <div className='table-wrapper'>
        {data?.storeShippingStepItems?.length ? (
          <table className='table table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder border-0 thead-color'>
                <th className='max-w-100px rounded-start px-5'>Purchase Price</th>
                <th className='min-w-140px'>Weight (Ounces)</th>
                <th className='min-w-150px'>Shipping - Per Item</th>
                <th className='min-w-150px rounded-end'>Shipping - Per Ounce</th>
              </tr>
            </thead>
            <tbody>
              {getRows()}
              <tr>
                <td></td>
                <td align='right' className='fw-bold default'>
                  DEFAULT
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <span>{`${data?.defaultShippingPerItem || 0} ${data?.currency}`}</span>
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <span>{`${data?.defaultShippingPerOunce || 0} ${data?.currency}`}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div>
            <span className='empty-info'>No shipping markups were added</span>
            <ButtonSecondary
              fontSize={14}
              onClick={() => changeTo(StoreSettingsTabs.shipping)}
              className='mb-8'
            >
              Add shipping pricing settings <img src={toAbsoluteUrl('/media/svg/misc/plus.svg')} />
            </ButtonSecondary>
            <hr className='custom-hr' />
          </div>
        )}
      </div>
    </Container>
  )
}

export { ShippingTable }

const Container = styled.div`
  .table-wrapper {
    padding-inline: 24px;
  }

  span {
    font-size: 14px;
    white-space: nowrap;
  }

  .default {
    color: #abadb8;
    font-size: 14px;
  }

  .thead-color {
    color: #3f4254;
    background-color: #e6f5fe;
  }

  .empty-info {
    display:block;
    font-size: 14px;
    font-weight: 500;
    color: #a1a5bd;
    margin-bottom 24px;
  }

  .custom-hr{
    border-top: 1px dashed #d9dbe3;
  }
`
const TdContent = styled.div`
  .invalid {
    color: tomato;
  }

  input {
    font-size: 11px;
    padding: 3px;
  }

  select {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    :focus {
      outline: none;
    }

    font-size: 11px;
    padding: 5px 20px 5px 15px;
    width: 60px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    background-color: #eff2f5;
  }
`
