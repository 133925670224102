import { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { planApis } from '../../../setup/apis/subscription-plan/planApis'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getPlans } from '../../modules/redux-toolkit/planSlicer'
import { planSelector } from '../../modules/selectors/reduxSelectors'
import { PlanOptionType } from '../account-creation/AccountCreation'
import { PlanCard } from './PlanCard'

type Props = {}

const Public4: FC<Props> = () => {
  const history = useHistory()
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false)
  const plansRef = useRef<any>(null)
  const planOptions = useSelector(planSelector)
  const [selectedPlan, setSelectedPlan] = useState<PlanOptionType | null>()
  const [arrowColors, setArrowColors] = useState<{ left: string; right: string }>({
    left: '#cccccc',
    right: '#009ef7',
  })
  const dispatch = useDispatch()

  useEffect(() => {
    _getPlans()
  }, [])

  function handleScroll(dir: string) {
    if (dir === 'left')
      plansRef.current.scrollBy({
        top: 0,
        left: -1120,
        behavior: 'smooth',
      })
    else if (dir === 'right')
      plansRef.current.scrollBy({
        top: 0,
        left: +1120,
        behavior: 'smooth',
      })
  }

  function handleMouseMove(e: any) {
    if (isMouseDown) {
      plansRef.current.scrollLeft -= e.movementX
    }
  }

  async function _getPlans() {
    const response = ((await planApis.getAll()) || [])?.sort(
      (item1: PlanOptionType, item2: PlanOptionType) => item1.priceInCents - item2.priceInCents
    )
    dispatch(getPlans(response))
  }

  function renderPlans() {
    return planOptions.map((item, i) => (
      <div key={i}>
        <PlanCard
          data={item}
          selected={item.id === selectedPlan?.id}
          goToReg={() => goToReg(item)}
        />
      </div>
    ))
  }

  function goToReg(item: PlanOptionType) {
    setSelectedPlan(item)
    history.push('/registration/create-account', { plan: item })
  }

  function _onScroll(e: any) {
    setArrowColors((prev) => ({
      ...prev,
      left: e.target.scrollLeft ? '#009ef7' : '#cccccc',
      right: e.target.scrollLeft >= 368 ? '#cccccc' : '#009ef7',
    }))
  }

  return (
    <Container arrowColors={arrowColors}>
      <div className='pricing-upper'>
        <div className='pricing-info'>
          <span className='pricing-header'>
            <div className='blue-line' />
            <img src={toAbsoluteUrl('/media/svg/misc/arrow-right-about-us.svg')} className='me-3' />
            Pricing
          </span>
          <span>Build the right plan</span>
          <span> up to your needs</span>
        </div>
        <div className='image-container'>
          <img src={toAbsoluteUrl('/media/svg/misc/decorative-elements.svg')} alt='' width={500} />
        </div>
      </div>
      {planOptions.length ? (
        <div
          className='plans-container'
          ref={plansRef}
          onMouseDown={(e) => setIsMouseDown((prev) => true)}
          onMouseUp={(e) => setIsMouseDown((prev) => false)}
          onMouseLeave={() => setIsMouseDown((prev) => false)}
          onMouseMove={(e) => handleMouseMove(e)}
          onScroll={(e) => _onScroll(e)}
        // onMouseMoveCapture={(e) => handleScroll('right')}
        >
          {renderPlans()}
        </div>
      ) : null}
      {planOptions.length ? (
        <div className='arrows'>
          <div className='arrows-header' onClick={() => handleScroll('left')}>
            <img
              src={toAbsoluteUrl(
                `/media/svg/misc/arrow-left-about-us${arrowColors.left === '#cccccc' ? '-grey' : ''
                }.svg`
              )}
              className='arrow-left'
            />
            <div className='blue-line-left' />
          </div>
          <div className='arrows-header' onClick={() => handleScroll('right')}>
            <div className='blue-line' />
            <img
              src={toAbsoluteUrl(
                `/media/svg/misc/arrow-right-about-us${arrowColors.right === '#cccccc' ? '-grey' : ''
                }.svg`
              )}
              className='me-3'
            />
          </div>
        </div>
      ) : null}
    </Container>
  )
}

export { Public4 }

const Container = styled.div<any>(
  ({ arrowColors }) => `
  width: 100%;
  min-height: 755px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 24px 120px;

  .pricing-upper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .pricing-info {
    display: flex;
    flex-direction: column;
    font-size: 54px;
    font-weight: 400;
    margin-top: 24px;
    .product-name {
      color: #009ef7;
    }
  }

  .pricing-header {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
    color: #009ef7;
    margin-bottom: 12px;
  }

  .plans-container {
    width: 1116px;
    padding: 24px 0 0 0;
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    cursor: grab;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: transparent;
    }

    scrollbar-width: 4px;
    scrollbar-color: transparent transparent;

    //------------- selecting words --------------------

    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .plans-container:active {
    cursor: grabbing;
  }

  .arrows {
    margin-top: 12px;
    display: flex;
  }

  // .button {
  //   padding: 8px 12px;
  //   border: 2px solid #1d1d25;
  //   border-radius: 8px;
  //   cursor: pointer;
  // }

  // .button:hover {
  //   background-color: #ececec;
  // }

  // .button:active {
  //   background-color: #cccccc;
  // }

  .arrows-header {
    display: flex;
    align-items: center;
    font-weight: 400;
    cursor: pointer;
    margin-inline: 6px;
  }

  .blue-line,
  .blue-line-left {
    width: 45px;
    height: 2.5px;
    background-color: ${arrowColors.right};
    margin: 0 -10px 0 0;
  }

  .blue-line-left {
    margin: 0 10px 0 0;
    background-color: ${arrowColors.left};

  }

  .arrow-left {
    margin-right: -9px;
  }

  @media (max-height: 1100px) {
    min-height: 100vh;
  }
`
)
