import {FC, ReactNode, useEffect} from 'react'
import styled from 'styled-components'
import {useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../setup'

type Props = {
  children: ReactNode
  width?: number
  height?: number
}

const CustomModal: FC<Props> = ({children, width, height}) => {
  const modal = useSelector<RootState>((st) => st.modal)

  return (
    <Container display={modal ? 'visible' : 'none'} width={width} height={height}>
      <Children>{children}</Children>
    </Container>
  )
}

export {CustomModal}

CustomModal.defaultProps = {
  width: 400,
  height: 300,
}

const Container = styled.div<any>(
  ({display, width, height}) => `
  position: fixed;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  z-index: 1501;
  background-color: #ffffff;
  width: ${width}px;
  height: ${height}px;
  display:${display};
  // box-shadow: 0 0 5px 1px #cccccc;
  border-radius: 15px;
  overflow:auto;
`
)

const Children = styled.div`
  width: 100%;
  height: 100%;
`
