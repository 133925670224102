import { FC } from 'react';
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { MultiColumnSelect, Option } from '../../../components/MultiColumnSelect'
import { FilterTypes, ListTypes } from '../../filters/importProductsFilters'
import { ListsTable } from './ListsTable'

type Props = {
  optionsc: Option[]
  childOptionsc: Option[]
  optionsb: Option[]
  manageParentLists: (option: Option, filterType: string, listType: string) => void
  manageChildOptionsc: (parent: string, listType: string, option?: Option) => void
  searchCategories: (name: string, listType: string, pageNumber: number, byScroll?: boolean) => void
  manageMultipleRows: (items: Option[], listType: string) => void
  categories: Option[]
  brands: Option[]
  totalCount: number
  totalCategoryCount: number
  categoryLoading?: boolean
  planCount: number
  searchText: string
  manageSearchText: (val: string, listType: string) => void
}

const WhiteListing: FC<Props> = ({
  optionsc,
  childOptionsc,
  optionsb,
  manageChildOptionsc,
  manageParentLists,
  manageMultipleRows,
  searchCategories,
  categories,
  brands,
  totalCount,
  totalCategoryCount,
  categoryLoading,
  planCount,
  searchText,
  manageSearchText,
}) => {
  function _removeRow(item: Option, filterType: string) {
    manageParentLists(item, filterType, ListTypes.WhiteList)
  }

  return (
    <Container>
      <div className={`min-height-40`}>
        <div className='header-two'>
          <span>WHITELISTING</span>
        </div>
        <MultiColumnSelect
          label='CATEGORY WHITELIST'
          filterType={FilterTypes.Category}
          listType={ListTypes.WhiteList}
          placeHolder='Select a category'
          options={optionsc}
          childOptions={childOptionsc}
          getChildOptions={manageChildOptionsc}
          manageTableRow={manageParentLists}
          manageMultipleRows={manageMultipleRows}
          tableRows={categories}
          searchCategories={searchCategories}
          totalCategoryCount={totalCategoryCount}
          loading={categoryLoading}
          searchText={searchText}
          manageSearchText={manageSearchText}
        />
        {categories?.length ? (
          <ListsTable
            className=''
            data={categories}
            title='Category'
            filterType={FilterTypes.Category}
            removeRow={_removeRow}
          />
        ) : null}
        {/* <hr className='my-10' />
        {categories?.length ? (
          <div className='brands-select-wrapper'>
            <label className='custom-label'>BRAND WHITELIST</label>
            <div className='brands-select'>
              <Select
                name='layout-builder[layout][header][width]'
                value={''}
                onChange={(option) =>
                  manageParentLists(option, FilterTypes.Brand, ListTypes.WhiteList)
                }
                options={optionsb?.filter(
                  (item) => brands.findIndex((it) => item.id === it.id) === -1
                )}
                placeholder='Select a brand'
                withRs
                withSearch
              />
              <div className='search-icon'>
                <FaSearch color='#a1a5bd' />
              </div>
            </div>
          </div>
        ) : null}
        {brands?.length && categories?.length ? (
          <ListsTable
            className=''
            data={brands}
            title='Brand'
            filterType={FilterTypes.Brand}
            removeRow={_removeRow}
          />
        ) : null} */}
      </div>
      <hr className='my-8' />
      <div className='footer-content'>
        <h2>{`TOTAL AMOUNT OF PRODUCTS:  ${totalCount}`}</h2>
        {totalCount > planCount ? (
          <div className='info-text'>
            <img src={toAbsoluteUrl('/media/svg/misc/warning-info.svg')} className='mb-1 me-3' />
            <div className='d-flex flex-column'>
              <span>The amount of products to import exceeds your current subsription plan.</span>
              <span>Please, consider upgrading the plan to sell more items.</span>
            </div>
          </div>
        ) : null}
      </div>
    </Container>
  )
}

export { WhiteListing }

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .min-height-40 {
    min-height: 40vh;
  }

  .min-height-100 {
    min-height: 100vh;
  }

  .header-two {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  .brands-select-wrapper {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
  }

  .brands-select {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .search-icon {
    position: absolute;
    top: 16px;
    left: 12px;
    cursor: pointer;
    z-index: 4;
  }

  .custom-label {
    min-width: 270px;
    font-size: 14px;
    font-weight: 600;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .info-text {
    border-radius: 8px;
    background-color: #fff4cc;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 12px;
    padding: 12px;
    border: 1px solid #ffeeb3;
    flex-shrink: 1;
  }
`
