import moment from 'moment'
import {FC, useState, useRef, useCallback, useEffect} from 'react'
import styled from 'styled-components'
import {Range} from '../layger/products/import-products/WhiteBlackListing'
type Props = {
  min: number
  max: number
  minValue: number
  maxValue: number
  onChange: (obj: Range) => void
  label: string
  unit: string
}

type Param = {
  from: number
  to: number
}

const MultiRangeSlider: FC<Props> = ({min, max, minValue, maxValue, onChange, label, unit}) => {
  const [minVal, setMinVal] = useState<number>(minValue)
  const [maxVal, setMaxVal] = useState<number>(maxValue)
  const [touched, setTouched] = useState<boolean>(false)

  const range = useRef<HTMLDivElement>(null)
  const minValRef = useRef(minValue)
  const maxValRef = useRef(maxValue)

  const getPercent = (value: number) => {
    return Math.round(((value - min) / (max - min)) * 100)
  }

  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)
    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal])

  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)
    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal])

  useEffect(() => {
    if (touched) {
      onChange({from: minVal, to: maxVal, updateDate: new Date(), productCount: 0})
    }
  }, [minVal, maxVal])
  return (
    <ParentContainer>
      <div className='label-container'>
        <label className='fs-3 fw-bolder'>{label}</label>
      </div>
      <Container>
        <div className='input-container'>
          <input
            type='range'
            min={min}
            max={max}
            value={minVal}
            className={`thumb thumb--left ${minVal > max - 100 && 'z-idx-5'}`}
            onChange={(event) => {
              setTouched(true)
              const value = Math.min(Number(event.target.value), maxVal)
              setMinVal(value)
              minValRef.current = value
            }}
          />
          <input
            type='range'
            min={min}
            max={max}
            value={maxVal}
            className='thumb thumb--right'
            onChange={(event) => {
              setTouched(true)
              const value = Math.max(Number(event.target.value), minVal)
              setMaxVal(value)
              maxValRef.current = value
            }}
          />
          <div className='slider__left-value'>{minVal}</div>
          <div className='slider__right-value'>{maxVal}</div>
        </div>
        <div className='slider'>
          <div className='slider__track' />
          <div ref={range} className='slider__range' />
          <div className='slider__init-value'>{0}</div>
          <div className='slider__quarter-value'>{250}</div>
          <div className='slider__half-value'>{500}</div>
          <div className='slider__three-quarters-value'>{750}</div>
          <div className='slider__unit-value'>{unit}</div>
        </div>
      </Container>
    </ParentContainer>
  )
}

export {MultiRangeSlider}

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 80px;

  .label-container {
    width: 330px;
    white-space: nowrap;
  }
`

const Container = styled.div<any>(
  (props) => `
    .slider {
        position: relative;
        width: 600px;
    }
    
    .slider__track,
    .slider__range {
        border-radius: 6px;
        height: 8px;
        position: absolute;
    }
    
    .slider__track {
        background-color: #e4e6ef;
        width: 100%;
        z-index: 1;
    }

    .slider__range {
        position:relative;
        background-color: #40b6f9;
        z-index: 2;
    }

    /* Removing the default appearance */
        .thumb,
        .thumb::-webkit-slider-thumb {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }

    .thumb {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 600px;
        outline: none;
    }

    .thumb--left {
        z-index: 3;
    }

    .thumb--right {
        z-index: 4;
    }

    .input-container {
      position:relative;
    }

    /* For Chrome browsers */
    .thumb::-webkit-slider-thumb {
        background-color: #f3faff;
        border: 1px solid #cdcfda;
        border-radius: 50%;
        box-shadow: 0 1px 3px 1px #ced4da;
        cursor: pointer;
        height: 24px;
        width: 24px;
        margin-top: 8px;
        pointer-events: all;
        position: relative;
    }

    /* For Firefox browsers */
    .thumb::-moz-range-thumb {
        background-color: #f3faff;
        border: 1px solid #cdcfda;
        border-radius: 50%;
        box-shadow: 0 3px 1px 0 #ced4da;
        cursor: pointer;
        height: 24px;
        width: 24px;
        margin-top: 8px;
        pointer-events: all;
        position: relative;
    }

    .z-idx-5{
        z-index:5;
    }
    
    .slider__init-value,
    .slider__quarter-value,
    .slider__half-value,
    .slider__three-quarters-value,
    .slider__unit-value,
    .slider__left-value,
    .slider__right-value {
        color: black;
        font-size: 13px;
        font-weight:600;
        position: absolute;
    }

    .slider__left-value {
        left: 0px;
        top: -30px;
    }

    .slider__right-value {
        right: -10px;
        top: -30px;
    }

    .slider__init-value,
    .slider__quarter-value,
    .slider__half-value,
    .slider__three-quarters-value,
    .slider__unit-value {
      color:#a1a5bd;
      font-weight:600;
      font-size:14px;
    }

    .slider__init-value {
      left: 0px;
      top: 20px;
    }

    .slider__quarter-value {
        left: 145px;
        top: 20px;
    }

    .slider__half-value {
        left: 290px;
        top: 20px;
    }

    .slider__three-quarters-value {
        left: 435px;
        top: 20px;
    }

    .slider__unit-value {
        right: 0px;
        top: 20px;
    }
`
)
