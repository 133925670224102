import {FC, useState, useEffect} from 'react'
import styled from 'styled-components'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ButtonPrimary, CustomHeader} from '../../../components/CustomStyledComponents'
import Select from '../../../components/Select'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {storeApis} from '../../../../setup/apis/store/storeApis'
import {useDispatch, useSelector} from 'react-redux'
import {getStores} from '../../../modules/redux-toolkit/storeSlicer'
import {marketPlaceApis} from '../../../../setup/apis/marketplaces/marketPlaceApis'
import {
  MarketplaceType,
  StoreType,
} from '../../../../setup/types/response-data-types/ResponseDataTypes'
import {triggerAlert} from '../../../components/Alert'
import {StoreDetailsType} from '../../../../setup/types/request-data-types/RequestDataTypes'
import {activeStoreSelector} from '../../../modules/selectors/reduxSelectors'
import {capitalize, preventSpaces} from '../../../utils/inputFieldUtils'

type Props = {
  storeId: number
}

const storeSchema = Yup.object().shape({
  label: Yup.string().trim().required('Store label is required'),
  // amazonSellerId: Yup.string().trim()
  //   .required('Amazon seller ID is required')
  //   .length(14, 'Invalid amazon seller ID (14 symbols)'),
  // amazonUserAccessCode: Yup.string().trim()
  //   .required('Amazon user access code is required')
  //   .length(20, 'Invalid amazon user access code (20 symbols)'),
  // amazonUserSecretKey: Yup.string().trim()
  //   .required('Amazon user secret key is required')
  //   .length(40, 'Invalid amazon user access code (40 symbols)'),
})

const initialStore: StoreDetailsType = {
  marketplaceCode: '',
  label: '',
  // amazonSellerId: '',
  // amazonUserAccessCode: '',
  // amazonUserSecretKey: '',
}

const StoreDetails: FC<Props> = ({storeId}) => {
  const stores = useSelector(activeStoreSelector)
  const [selectedStore, setSelectedStore] = useState<StoreType>({...stores[0], storeId: -1})
  const [marketplace, setMarketplace] = useState<MarketplaceType>({marketplaceId: '', label: ''})
  const [marketPlaces, setMarketPlaces] = useState<MarketplaceType[]>([])
  const [cleared, setCleared] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [authLoading, setAuthLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: initialStore,
    validationSchema: storeSchema,
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting}) => {
      saveChanges(values)
    },
  })

  useEffect(() => {
    if (storeId !== selectedStore?.storeId) {
      const store = stores?.find((item) => item.storeId === storeId) || stores?.[0]
      setSelectedStore(store)
      formik.values.label = store?.label || ''
      // formik.values.amazonSellerId = store?.amazonSellerId || ''
      // formik.values.amazonUserAccessCode = store?.amazonUserAccessCode || ''
      // formik.values.amazonUserSecretKey = store?.amazonUserSecretKey || ''
      if (marketPlaces.length) {
        setMarketplace(
          marketPlaces?.find((item) => store?.marketplaceCode === item?.marketplaceId) ||
            marketPlaces[0]
        )
        formik.values.marketplaceCode = store?.marketplaceCode
      } else {
        _getMarketPlaces(store?.marketplaceCode)
      }
    }
  }, [storeId])

  async function saveChanges(values: StoreDetailsType) {
    try {
      setLoading(true)
      const response = await storeApis.updateDetails(storeId, {
        ...values,
        label: capitalize(values.label),
        // amazonSellerId: values.amazonSellerId?.toUpperCase(),
        // amazonUserAccessCode: values.amazonUserAccessCode?.toUpperCase(),
      })
      if (response?.status === 200) {
        triggerAlert('Changes saved successfully!')
        dispatch(
          getStores(
            stores.map((item) => (item.storeId === response?.data?.storeId ? response?.data : item))
          )
        )
      }
    } catch (e) {
      console.log('error', e)
      triggerAlert('Something went wrong...')
    } finally {
      setLoading(false)
    }
  }

  async function _getMarketPlaces(mCode: string) {
    const response = await marketPlaceApis.getAll()
    const selectedData = mCode
      ? response?.data?.find((item: any) => mCode === item?.marketplaceId)
      : response?.data[0]
    setMarketPlaces(response?.data)
    setMarketplace(selectedData)
    formik.values.marketplaceCode = mCode
  }

  function _onChangeMarketplace(option: MarketplaceType) {
    formik.values.marketplaceCode = option.marketplaceId
    setMarketplace(option)
  }

  function isVisible(name: keyof StoreDetailsType) {
    return formik.values[name]
  }

  function clearInput(name: keyof StoreDetailsType) {
    setCleared((prev) => !prev)
    switch (name) {
      case 'label':
        formik.values.label = ''
        return
      // case 'amazonUserSecretKey':
      //   formik.values.amazonUserSecretKey = ''
      //   return
      // case 'amazonSellerId':
      //   formik.values.amazonSellerId = ''
      //   return
      // case 'amazonUserAccessCode':
      //   formik.values.amazonUserAccessCode = ''
      //   return
    }
  }

  async function connectToAmazon() {
    try {
      setAuthLoading(true)
      const response = await storeApis.connectToAmazon(selectedStore?.storeId || 0)
      if (response && typeof response === 'string') {
        window.location.href = response
      }
    } catch (e: any) {
      setAuthLoading(false)
      console.log(e)
    }
  }

  return (
    <Container
      className='card'
      onSubmit={(e) => {
        e.preventDefault()
        formik.handleSubmit()
      }}
    >
      <div className='d-flex align-items-center justify-content-between'>
        <CustomHeader>Store Details</CustomHeader>
      </div>
      <div className='data-wrapper'>
        <div className='input-wrapper'>
          <label className='bold-label'>Marketplace</label>
          <Select
            name='marketPlace'
            value={marketplace}
            onChange={(option) => _onChangeMarketplace(option)}
            options={marketPlaces}
            disabled
            withRs
          />
        </div>
        <div className='input-wrapper'>
          <label className='bold-label'>Store title</label>
          <div className='icon-container'>
            <input
              type='text'
              placeholder='Insert store title'
              autoComplete='off'
              {...formik.getFieldProps('label')}
              className={clsx(
                'input-value input-cap',
                {'input-invalid': formik.errors.label},
                {
                  'is-valid': !formik.errors.label,
                }
              )}
            />
            {isVisible('label') ? (
              <div className='icon' onClick={() => clearInput('label')}>
                <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
              </div>
            ) : null}
          </div>
          {formik.errors.label && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.label}</span>
              </div>
            </div>
          )}
        </div>
        {!selectedStore?.authorizedWithAmazon ? (
          <ButtonPrimary
            type='button'
            disabled={authLoading || loading}
            className='mt-5'
            onClick={connectToAmazon}
          >
            {authLoading ? (
              <span className='spinner-border spinner-border-sm align-middle mx-2'></span>
            ) : null}{' '}
            Connect to Amazon
          </ButtonPrimary>
        ) : null}
        {/* <div className='input-wrapper'>
          <label className='bold-label'>Amazon Seller ID</label>
          <div className='icon-container'>
            <input
              type='text'
              placeholder='Insert amazon seller ID'
              autoComplete='off'
              onKeyDown={(e) => {
                preventSpaces(e)
              }}
              {...formik.getFieldProps('amazonSellerId')}
              className={clsx(
                'input-value input-uppercase',
                {
                  'input-invalid': formik.errors.amazonSellerId,
                },
                {
                  'is-valid': !formik.errors.amazonSellerId,
                }
              )}
            />
            {isVisible('amazonSellerId') ? (
              <div className='icon' onClick={() => clearInput('amazonSellerId')}>
                <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
              </div>
            ) : null}
          </div>
          {formik.errors.amazonSellerId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.amazonSellerId}</span>
              </div>
            </div>
          )}
        </div>
        <div className='input-wrapper'>
          <label className='bold-label'>Amazon user access code</label>
          <div className='icon-container'>
            <input
              type='text'
              placeholder='Insert user access code'
              autoComplete='off'
              onKeyDown={(e) => {
                preventSpaces(e)
              }}
              {...formik.getFieldProps('amazonUserAccessCode')}
              className={clsx(
                'input-value input-uppercase',
                {
                  'input-invalid': formik.errors.amazonUserAccessCode,
                },
                {
                  'is-valid': !formik.errors.amazonUserAccessCode,
                }
              )}
            />
            {isVisible('amazonUserAccessCode') ? (
              <div className='icon' onClick={() => clearInput('amazonUserAccessCode')}>
                <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
              </div>
            ) : null}
          </div>
          {formik.errors.amazonUserAccessCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.amazonUserAccessCode}</span>
              </div>
            </div>
          )}
        </div>
        <div className='input-wrapper'>
          <label className='bold-label'>Amazon user secret key</label>
          <div className='icon-container'>
            <input
              type='text'
              placeholder='Insert user secret key'
              autoComplete='off'
              onKeyDown={(e) => {
                preventSpaces(e)
              }}
              {...formik.getFieldProps('amazonUserSecretKey')}
              className={clsx(
                'input-value',
                {
                  'input-invalid': formik.errors.amazonUserSecretKey,
                },
                {
                  'is-valid': !formik.errors.amazonUserSecretKey,
                }
              )}
            />
            {isVisible('amazonUserSecretKey') ? (
              <div className='icon' onClick={() => clearInput('amazonUserSecretKey')}>
                <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
              </div>
            ) : null}
          </div>
          {formik.errors.amazonUserSecretKey && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.amazonUserSecretKey}</span>
              </div>
            </div>
          )}
        </div> */}
      </div>
      <hr className='m-0' />
      <div className='button-wrapper'>
        <ButtonPrimary
          type='submit'
          disabled={
            Object.values(formik.errors).length > 0 ||
            Object.values(formik.values).some((item) => !item) ||
            loading ||
            authLoading
          }
        >
          {loading ? (
            <span className='spinner-border spinner-border-sm align-middle mx-2'></span>
          ) : null}{' '}
          Review and apply store details
        </ButtonPrimary>
      </div>
    </Container>
  )
}

export {StoreDetails}

const Container = styled.form`
  min-width: 900px;
  overflow-x: auto;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);

  border-radius: 8px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  .data-wrapper {
    padding: 24px;
  }

  .input-wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding-block: 10px;
  }

  .bold-label {
    font-size: 16px;
    font-weight: 600;
    color: #3f4254;
    white-space: nowrap;
    margin-bottom: 4px;
  }

  .input-value {
    display: block;
    width: 100%;
    border: 1px solid #cdcfda;
    border-radius: 8px;
    padding: 11px;
    font-size: 16px;
    font-weight: 500;
    color: #3f4254;
  }

  .input-invalid {
    border: 1px solid tomato;
  }

  .input-value:focus,
  .input-invalid:focus {
    outline: none;
    box-shadow: 0 0 1px 3px #c0e7fd;
    border: 1px solid #cdcfda;
  }

  .info-text {
    width: 70%;
    padding: 16px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: #ccecfd;
    color: #005f94;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
  }

  .button-wrapper {
    display: flex;
    margin: 24px;
  }

  .message-wrapper {
    display: flex;
    flex-direction: column;
  }

  .message {
    display: flex;
    align-items: center;
  }

  .bulletos {
    width: 3.5px;
    height: 3.5px;
    background-color: #005f94;
    border-radius: 50%;
    margin-right: 5px;
  }

  .input-cap,
  .input-uppercase {
    text-transform: capitalize;

    //placeholder
    ::-webkit-input-placeholder {
      text-transform: none;
    }
    :-moz-placeholder {
      text-transform: none;
    }
    ::-moz-placeholder {
      text-transform: none;
    }
    :-ms-input-placeholder {
      text-transform: none;
    }
    ::placeholder {
      text-transform: none;
    }
  }

  .input-uppercase {
    text-transform: uppercase;
  }

  .icon-container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  /////// placeholder
  // ::-webkit-input-placeholder {
  //   text-transform: lowercase;
  // }

  // ::-moz-placeholder {
  //   text-transform: lowercase;
  // }

  // ::-moz-placeholder {
  //   text-transform: lowercase;
  // }

  // ::-ms-input-placeholder {
  //   text-transform: lowercase;
  // }
`
