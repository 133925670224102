import { FC, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ButtonSecondary } from '../../components/CustomStyledComponents'
import { useSelector } from 'react-redux'
import { CountryType } from '../../../setup/types/response-data-types/ResponseDataTypes'
import { RegistrationType } from '../registration/SubscriptionPlan'
import { PaymentMethod } from './PaymentMethod'
import { DetailsType, initialDetails } from './AccountCreation'
import { countrySelector } from '../../modules/selectors/reduxSelectors'

type Props = {
  handleSaved: (saved: boolean, values?: DetailsType) => void
  values: DetailsType
}

const AccountInfo: FC<Props> = ({ handleSaved, values }) => {
  const history = useHistory<RegistrationType>()
  const state = history.location?.state
  const data = useRef<DetailsType>(initialDetails)
  const [cleared, setCleared] = useState<boolean>(false)
  const countryOptions = useSelector(countrySelector)
  const [country, setCountry] = useState<CountryType>({ countryId: -1, label: '' })
  const [isVisiblePass, SetIsVisiblePass] = useState<boolean>()
  const [isVisibleConfPass, SetIsVisibleConfPass] = useState<boolean>()

  useEffect(() => {
    if (state?.details) {
      data.current = state.details
    }
  }, [])

  return (
    <Container className='details-body'>
      <div className='form-row'>
        <PaymentMethod />
      </div>
      <div>
        <div className='form-row'>
          <div className='form-item-left'>
            <label className='input-label'>First Name</label>
            <input
              type='text'
              autoComplete='off'
              readOnly
              className='input-value'
              value={values.firstName}
            />
          </div>
          <div className='form-item'>
            <label className='input-label'>Last Name</label>
            <input
              type='text'
              autoComplete='off'
              readOnly
              className='input-value'
              value={values.lastName}
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-item-left'>
            <label className='input-label'>Email</label>
            <input
              type='text'
              autoComplete='off'
              readOnly
              className='input-value'
              value={values.email}
            />
          </div>
          <div className='form-item' />
        </div>
        <div className='form-row'>
          <div className='form-item-left'>
            <label className='input-label'>Password</label>
            <input
              type='password'
              autoComplete='off'
              readOnly
              className='input-value'
              value={values.password}
            />
            <div className='fv-help-block mt-1 info-color'>
              <span role='alert'>{`Your password should include at least 8 characters with small and capital letters, numbers, and special characters.`}</span>
            </div>
          </div>
          <div className='form-item'>
            <label className='input-label'>Confirm Password</label>
            <input
              type='password'
              autoComplete='off'
              readOnly
              className='input-value'
              value={values.confirmPassword}
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-item-left'>
            <label className='input-label'>Username</label>
            <input
              type='text'
              autoComplete='off'
              readOnly
              className='input-value'
              value={values.username}
            />
          </div>
          <div className='form-item'>
            <label className='input-label'>Country</label>
            <input
              type='text'
              autoComplete='off'
              readOnly
              className='input-value'
              value={values.countryName}
            />
          </div>
        </div>
      </div>
      <div className='save-button'>
        <ButtonSecondary className='px-10' type='submit' onClick={() => handleSaved(false)}>
          Change details
        </ButtonSecondary>
      </div>
    </Container>
  )
}

export { AccountInfo }

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: start;
  width: 100%;
  .header-buttons {
    padding: 0 24px;
    display: flex;

    div {
      margin-left: 12px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    width: 100%;
  }

  .info-color {
    color: #a1a5bd;
  }

  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .form-item,
  .form-item-single,
  .form-item-left {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 6px 0;
    margin-bottom: 4px;
  }

  .form-item-left {
    margin-right: 30px;
  }

  .form-item-single {
    width: 50%;
  }

  .input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .input-value {
    display: block;
    border: none;
    border-bottom: 1px solid #cdcfda;
    padding-block: 11px;
    font-size: 14px;
    font-weight: 500;
    color: #3f4254;
    cursor: default;
  }

  .input-value:focus {
    outline: none;
    border: none;
    border-bottom: 1px solid #cdcfda;
    cursor: default;
  }

  .create-account {
    margin: 24px;
  }

  .icon-container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  .input-invalid {
    border: 1px solid tomato;
  }

  .save-button {
    margin-block: 24px;
  }
`
