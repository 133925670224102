import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

type Props = {}

const Public2: FC<Props> = () => {
    const history = useHistory()

    return (
        <Container>
            <div className='image-container'>
                <img src={toAbsoluteUrl('/media/svg/misc/about-us-illustration1.svg')} alt="" width={500} />
            </div>
            <div className='about-us-info'>
                <span className='about-us-header'>
                    <div className='blue-line' />
                    <img src={toAbsoluteUrl('/media/svg/misc/arrow-right-about-us.svg')} className='me-3' />
                    About us
                </span>
                <span><span className='product-name'>Market Gap</span> is a proprietary</span>
                <span>ecommerce solutions technology</span>
                <span>that empowers its members to sell</span>
                <span>millions of products across the</span>
                <span>world's leading online</span>
                <span>marketplaces, and to buy said</span>
                <span>products only after you have</span>
                <span>already sold them.</span>
            </div>
        </Container>
    )
}

export { Public2 }

const Container = styled.div`
    width: 100%;
    min-height: 755px;
    display: flex;
    justify-content: space-between;
    align-items:center;
    background-color: #ffffff;
    padding: 48px 120px;

    .about-us-info {
        display:flex;
        flex-direction:column;
        font-size:34px;
        font-weight:300;
        .product-name {
            color:#009EF7;
        }
    }

    .about-us-header {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 400;
        color: #009ef7;
        margin-bottom: 24px;
    }
    
    .blue-line {
        width: 45px;
        height: 2.5px;
        background-color: #009ef7;
        margin: 0 -10px 0 0;
    }

    @media  (max-height:1100px) {
        min-height:100vh;
    }
`
