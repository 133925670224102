import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Feature } from './Feature'

type Props = {}

const Public3: FC<Props> = () => {
  const history = useHistory()

  return (
    <Container>
      <div className='sky' />
      <div className='sky-2' />
      <div className='public-3-upper'>
        <div className='about-us-info'>
          <span className='about-us-header'>
            <div className='blue-line' />
            <img src={toAbsoluteUrl('/media/svg/misc/arrow-right-about-us.svg')} className='me-3' />
            About us
          </span>
          <span>Our intuitive software monitors</span>
          <span>millions of unique products offered</span>
          <span>in real time on online</span>
          <span>marketplaces, enabling our</span>
          <span>members to automate listing,</span>
          <span>delisting, currency conversion,</span>
          <span>profit markup, and shipping.</span>
          <span>We offer any assortment of</span>
          <span>products that we monitor with the</span>
          <span>click of a button in concurrent</span>
          <span>marketplace across the globe.</span>
        </div>
        <div className='image-container'>
          <img
            src={toAbsoluteUrl('/media/svg/misc/about-us-illustration2.svg')}
            alt=''
            width={500}
          />
        </div>
      </div>
      <div className='public-3-lower'>
        <div className='why-header'>
          <span>Why choose Market Gap</span>
        </div>
        <div className='features'>
          <Feature />
          <Feature />
          <Feature />
        </div>
      </div>
    </Container>
  )
}

export { Public3 }

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1d1d25;
  color: #ffffff;
  padding: 48px 120px;
  position: relative;

  .sky {
    position: absolute;
    width: 599.58px;
    height: 652.85px;
    left: 0;
    top: 800px;

    background: linear-gradient(180deg, rgba(84, 84, 212, 0.2) 0%, rgba(84, 84, 212, 0.084) 100%);
    filter: blur(300px);
    border-radius: 233.5px;
    transform: matrix(-0.58, -0.81, -0.81, 0.58, 0, 0);
    z-index:2;
  }

  .sky-2 {
    position: absolute;
    width: 467px;
    height: 1399.3px;
    left: 0;
    top: 400px;

    background: linear-gradient(
      180deg,
      rgba(251, 168, 28, 0.104) 0%,
      rgba(224, 86, 136, 0.06) 100%
    );
    filter: blur(300px);
    border-radius: 233.5px;
    transform: rotate(65.71deg);
    z-index:2;
  }

  .public-3-upper {
    width: 100%;
    min-height: 755px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 400px;

    @media  (max-height:1100px) {
        min-height:100vh;
    }
  }

  .public-3-lower {
    width: 100%;
    min-height: 419px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin-bottom: 100px;

    @media  (max-height:550px) {
      min-height:50vh;
    }
  }

  .about-us-info {
    display: flex;
    flex-direction: column;
    font-size: 34px;
    font-weight: 300;
    .product-name {
      color: #009ef7;
    }
  }

  .about-us-header {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
    color: #009ef7;
    margin-bottom: 24px;
  }

  .blue-line {
    width: 45px;
    height: 2.5px;
    background-color: #009ef7;
    margin: 0 -10px 0 0;
  }

  .why-header {
    font-size: 64px;
    font-weight: 400;
    margin-bottom: 96px;
  }

  .features {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: auto;
  }

  .arrows {
    margin-top: 24px;
    display: flex;
  }

  .button {
    padding: 8px 12px;
    border: 2px solid #ffffff;
    border-radius: 8px;
    cursor: pointer;
  }

  .button:hover {
    background-color: #3a3b3c;
  }

  .button:active {
    background-color: #cccccc;
  }
`
