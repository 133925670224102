import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import type { OrderType } from './OrderList'
import { OrderItemTable, OrderProductType } from './OrderItemTable'
import { OrderInfo } from './OrderInfo'
import { ButtonSecondary, CustomHeader } from '../../components/CustomStyledComponents'
import { FaChevronLeft } from 'react-icons/fa'
import { orderApis } from '../../../setup/apis/order/orderApis'

type Props = {}

const OrderDetails: React.FC<Props> = ({ ...props }) => {
  const history = useHistory<{ data: OrderType }>()
  const data = history?.location?.state?.data
  const [products, setProducts] = useState<OrderProductType[]>([])

  useEffect(() => {
    getProducts()
  }, [])

  async function getProducts() {
    const response = await orderApis.getItemsById(data.storeMicroOrderId)
    setProducts(response)
  }

  return (
    <Container className='card mb-5 mb-xl-10'>
      <div className='d-flex justify-content-between align-items-center'>
        <CustomHeader>Order Details</CustomHeader>
        <ButtonSecondary
          className='mx-8'
          onClick={() => history.push('/orders/order-list', { data })}
        >
          <FaChevronLeft /> Back To Orders
        </ButtonSecondary>
      </div>
      <hr className='m-0' />
      <OrderInfo data={data} />
      <hr className='m-0' />
      <OrderItemTable data={products} header='Items' />
    </Container>
  )
}

export { OrderDetails }

const Container = styled.div`
  min-width: 1000px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
`
