import {Redirect, Route, Switch} from 'react-router-dom'
import {MenuTestPage} from '../pages/MenuTestPage'
import {Notifications} from '../layger/notifications/Notifications'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import ProfilePage from '../modules/profile/ProfilePage'
import WizardsPage from '../modules/wizards/WizardsPage'
import AccountPage from '../modules/accounts/AccountPage'
import WidgetsPage from '../modules/widgets/WidgetsPage'
import ChatPage from '../modules/apps/chat/ChatPage'
import StorePage from '../layger/stores/StorePage'
import ProductPage from '../layger/products/ProductPage'
import BrandPage from '../layger/brands/BrandPage'
import CategoryPage from '../layger/categories/CategoryPage'
import OrderPage from '../layger/orders/OrderPage'
import ReportPage from '../layger/reports/ReportPage'
import ContactPage from '../layger/contact-us/ContactPage'
import SettingPage from '../layger/settings/SettingPage'

export function PrivateRoutes() {
  return (
    <Switch>
      <Route path='/stores' component={StorePage} />
      <Route path='/products' component={ProductPage} />
      <Route path='/brands' component={BrandPage} />
      <Route path='/categories' component={CategoryPage} />
      <Route path='/orders' component={OrderPage} />
      <Route path='/dashboard' component={ReportPage} />
      <Route path='/contact-us' component={ContactPage} />
      <Route path='/settings' component={SettingPage} />
      <Route path='/notifications' component={Notifications} />
      <Route path='/builder' component={BuilderPageWrapper} />
      <Route path='/crafted/pages/profile' component={ProfilePage} />
      <Route path='/crafted/pages/wizards' component={WizardsPage} />
      <Route path='/crafted/widgets' component={WidgetsPage} />
      <Route path='/crafted/account' component={AccountPage} />
      <Route path='/apps/chat' component={ChatPage} />
      <Route path='/menu-test' component={MenuTestPage} />
      <Redirect from='/auth' to='/orders/order-list' />
      <Redirect exact from='/' to='/orders/order-list' />
      <Redirect to='error/404' />
    </Switch>
  )
}
