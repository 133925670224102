import React, { useEffect, useState, createContext, useContext } from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { MasterInit } from './MasterInit'
import { PageDataProvider } from './core'
import {
  DrawerMessenger,
  ExploreMain,
  ActivityDrawer,
  Main,
  InviteUsers,
  UpgradePlan,
} from '../partials'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../setup'
import { Overlay } from '../../app/components/CustomStyledComponents'
import { getStores } from '../../app/modules/redux-toolkit/storeSlicer'
import { storeApis } from '../../setup/apis/store/storeApis'
import { NotificationsTypeBySignalR } from '../../setup/types/response-data-types/ResponseDataTypes'
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import * as signalR from '@microsoft/signalr'
import { tokenSelector } from '../../app/modules/selectors/reduxSelectors'

const initialnotifications = { count: 0, unreadNotificationCountByStoreModel: [] }

export const NotificationContext = createContext<NotificationsTypeBySignalR>(initialnotifications)

const MasterLayout: React.FC = ({ children }) => {
  const modal = useSelector<RootState>((state) => state.modal)
  const dispatch = useDispatch()
  const [hubConnection, setHubConnection] = useState<HubConnection>()
  const accessToken = useSelector(tokenSelector)
  const [notifications, setNotifications] = useState<NotificationsTypeBySignalR>(initialnotifications)

  useEffect(() => {
    _getStores()
    createHubConnection()
  }, [])

  useEffect(() => {
    if (hubConnection) {
      hubConnection.on('ReceiveUnreadNotifications', (obj: NotificationsTypeBySignalR) => {
        setNotifications(prev => obj)
      })
    }
  }, [hubConnection])

  const createHubConnection = async () => {
    const hubConnection = new HubConnectionBuilder().withUrl(`${process.env.REACT_APP_PROXY}/notification-hub`, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
      accessTokenFactory: () => accessToken
    }).build()
    try {
      await hubConnection.start()
      setHubConnection(hubConnection)
    } catch (e) {
      console.log(e)
    }
  }

  async function _getStores() {
    const response = await storeApis.getAll()
    dispatch(getStores(response?.data) || [])
  }

  return (
    <NotificationContext.Provider value={notifications}>
      <PageDataProvider>
        <Container className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />
            {/* <div id='kt_content' className='content d-flex flex-column flex-column-fluid'> */}
            {/* <Toolbar /> */}
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>{children}</Content>
            </div>
            {/* </div> */}
            {/* <Footer /> */}
          </div>
        </Container>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        {/* <ExploreMain /> */}
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <Main />
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        {modal ? <Overlay /> : null}
        <MasterInit />
        <ScrollTop />
      </PageDataProvider>
    </NotificationContext.Provider>
  )
}

export { MasterLayout }

const Container = styled.div`
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
