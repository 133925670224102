import {FC, useEffect, useState} from 'react'
import {FaChevronCircleRight, FaChevronRight, FaChevronUp} from 'react-icons/fa'
import {useHistory} from 'react-router'
import styled from 'styled-components'
import {
  ButtonDanger,
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiarry,
  CustomHeader,
} from '../../../components/CustomStyledComponents'
import {CustomModal} from '../../../components/CustomModal'
//redux
import {useSelector, useDispatch} from 'react-redux'
import {toggle} from '../../../modules/redux-toolkit/modalSlicer'
import {StoreType} from '../../../../setup/types/response-data-types/ResponseDataTypes'
import {PlanOptionType} from '../../account-creation/AccountCreation'
import moment from 'moment'
import {allStoreSelector, planSelector} from '../../../modules/selectors/reduxSelectors'
import {planApis} from '../../../../setup/apis/subscription-plan/planApis'
import {storeApis} from '../../../../setup/apis/store/storeApis'
import {getStores} from '../../../modules/redux-toolkit/storeSlicer'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ChangePaymentMethodType} from '../../../../setup/types/request-data-types/RequestDataTypes'
import {getPlans} from '../../../modules/redux-toolkit/planSlicer'

type Props = {}

const Subscriptions: FC<Props> = ({}) => {
  const planOptions = useSelector(planSelector)
  const [selectedPlan, setSelectedPlan] = useState<PlanOptionType | undefined>(planOptions[0])
  const [selectedStore, setSelectedStore] = useState<StoreType>()
  const [detailedStore, setDetailedStore] = useState<StoreType | null>()
  const [loading, setLoading] = useState<boolean>(false)
  const stores = useSelector(allStoreSelector)
  const [chosenPlans, setChosenPlans] = useState<PlanOptionType[]>([])
  const history =
    useHistory<{from?: string; store?: StoreType; plan?: PlanOptionType; url?: string}>()
  const dispatch = useDispatch()

  useEffect(() => {
    getPlanOptions()
  }, [])

  async function getPlanOptions() {
    if (!planOptions.length) {
      const response = ((await planApis.getAll()) || [])?.sort(
        (item1: PlanOptionType, item2: PlanOptionType) => item1.priceInCents - item2.priceInCents
      )
      dispatch(getPlans(response))
    }
  }

  function _manageChosenPlans(plan: PlanOptionType) {
    setChosenPlans((prev) => {
      const exists = prev.filter((item) => item.id === plan.id)
      if (exists.length) {
        return prev.filter((item) => item.id !== plan.id)
      }
      return [...prev, plan]
    })
  }

  async function _unSubscribe() {
    setSelectedPlan(undefined)
    try {
      setLoading(true)
      const response = await storeApis.unSubscribe(selectedStore?.storeId || 0)
      dispatch(getStores((await storeApis.getAll())?.data || []))
      dispatch(toggle())
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  async function _reSubscribe(store: StoreType) {
    setSelectedStore(store)
    try {
      setLoading(true)
      const response = await storeApis.reSubscribe(store?.storeId || 0)
      dispatch(getStores((await storeApis.getAll())?.data || []))
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  async function changePaymentMethod(store: StoreType) {
    const url = `${process.env.REACT_APP_PUBLIC_URL}/settings/account-settings`
    try {
      setLoading(true)
      const requestData: ChangePaymentMethodType = {
        storeId: store?.storeId,
        successCallbackUrl: url,
        cancelCallbackUrl: url,
      }
      const response = await storeApis.changePaymentMethod(requestData)
      if (response?.data && typeof response?.data === 'string') {
        history.push('/stripe-checkout', {url: response?.data})
      }
    } catch (e) {
      console.log('error', e)
    } finally {
      setLoading(false)
    }
  }

  function _selectStore(store: StoreType) {
    setSelectedStore(store)
  }

  function _selectPlan(plan: PlanOptionType) {
    setSelectedPlan(plan)
  }

  function _getDaysLeft(dateFrom: any, dateTo: any, forProgressBar?: boolean) {
    const daysLeft = Math.ceil(Math.abs(dateTo - dateFrom) / 86400000)
    if (forProgressBar) return (daysLeft * 100) / 30
    return daysLeft
  }

  function changePlan(store: StoreType) {
    history.push('/settings/plan-edit', {
      from: 'settings',
      store,
      plan: planOptions.find((item) => item.priceInCents / 100 === store.priceInUsd),
    })
  }

  function buyPlan(plan: PlanOptionType) {
    history.push('/settings/plan-edit', {from: 'settings', plan})
  }

  function _getUncompletedSubscriptions() {
    return stores
      ?.filter((item) => !item?.subscriptionPlanId)
      ?.map((item: StoreType) => (
        <div className='store-wrapper'>
          <h3 className='mb-5'>{item.label}</h3>
          <div className={`store-container`} onClick={() => _selectStore(item)}>
            <div className='plan-price'>
              <div className='num-of-listings'>
                <span>
                  {planOptions.find((plan) => Math.floor(plan.listingsAmount) === item.listings)
                    ?.name || '0 Listings'}
                </span>
              </div>
              <span className='dollar-sign'>$</span>
              <span className='price'>{item?.priceInUsd}</span>
              <span className='month'>/month</span>
            </div>
            <div className='d-flex align-items-center'>
              <span className='non-purchased-info'>
                The subscription hasn’t been purschased yet
              </span>
              <div className='min-w-150px mx-5'>
                <ButtonSecondary
                  fontSize={13}
                  paddingx={24}
                  paddingy={4}
                  className='text-nowrap w-100'
                  onClick={() => changePlan(item)}
                >
                  Buy plan
                </ButtonSecondary>
              </div>
            </div>
          </div>
        </div>
      ))
  }

  function _getStores() {
    return stores
      ?.filter((item) => !item?.subscriptionCanceled && item?.subscriptionPlanId)
      ?.map((item: StoreType) =>
        item.storeId === detailedStore?.storeId ? (
          <div className='store-wrapper'>
            <h3 className='mb-5'>{item.label}</h3>
            <div className='detailed-store'>
              <div className='store-info'>
                <div className='store-upper'>
                  <div className='num-of-listings'>
                    <span>
                      {planOptions.find((plan) => Math.floor(plan.listingsAmount) === item.listings)
                        ?.name || '0 Listings'}
                    </span>
                  </div>
                  <div className='d-flex'>
                    <span className='dollar-sign'>$</span>
                    <span className='price'>{item?.priceInUsd}</span>
                    <span className='month'>/month</span>
                  </div>
                </div>

                <div className='info-lower'>
                  <div className='duration'>
                    <span className='left-days-date'>
                      Due date {moment(new Date(item?.nextPaymentDate || new Date())).format('l')}
                    </span>
                    <span className='left-days-num'>
                      {_getDaysLeft(new Date(), new Date(item?.nextPaymentDate || new Date()))} days
                      left
                    </span>
                    <div className='progress-track'>
                      <div
                        className='progress-bar'
                        style={{
                          width: `${_getDaysLeft(
                            new Date(),
                            new Date(item?.nextPaymentDate || new Date()),
                            true
                          )}%`,
                        }}
                      />
                    </div>
                  </div>
                  <ButtonPrimary paddingy={10} fontWeight={500} onClick={() => changePlan(item)}>
                    Change Plan <FaChevronCircleRight className='ms-1' />
                  </ButtonPrimary>
                </div>
              </div>
              <div className='store-plan-info'>
                <ButtonTertiarry
                  onClick={() => setDetailedStore(null)}
                  className='align-self-end my-5'
                >
                  Show less <FaChevronUp className='ms-2' />
                </ButtonTertiarry>
                <div className='next-payment-date'>
                  <div className='d-flex flex-column'>
                    <span className='store-plan-info-label'>Next Payment Date</span>
                    <span className='store-plan-info-value'>
                      on {moment(new Date(item?.nextPaymentDate || new Date())).format('ll')}
                    </span>
                  </div>
                  <ButtonDanger paddingy={5} fontSize={13} onClick={() => dispatch(toggle())}>
                    Cancel subscription
                  </ButtonDanger>
                </div>
                <div className='remained-listings'>
                  <span className='store-plan-info-label'>Remained Listings</span>
                  <span className='store-plan-info-value'>{`${item?.remainedListings} out of ${item?.listings}`}</span>
                </div>
                <div className='active-products'>
                  <span className='store-plan-info-label'>Active Products</span>
                  <span className='store-plan-info-value'>{item?.activeProductCount}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='store-wrapper'>
            <h3 className='mb-5'>{item.label}</h3>
            <div className={`store-container`} onClick={() => _selectStore(item)}>
              <div className='plan-price'>
                <div className='num-of-listings'>
                  <span>
                    {planOptions.find((plan) => Math.floor(plan.listingsAmount) === item.listings)
                      ?.name || '0 Listings'}
                  </span>
                </div>
                <span className='dollar-sign'>$</span>
                <span className='price'>{item?.priceInUsd}</span>
                <span className='month'>/month</span>
              </div>
              <div className='d-flex'>
                <ButtonSecondary
                  fontSize={13}
                  paddingx={24}
                  paddingy={4}
                  className='text-nowrap'
                  onClick={() => changePaymentMethod(item)}
                  disabled={loading && selectedStore?.storeId === item.storeId}
                >
                  Change payment method
                </ButtonSecondary>
                <div className='min-w-150px mx-5'>
                  <ButtonSecondary
                    fontSize={13}
                    paddingx={24}
                    paddingy={4}
                    className='text-nowrap w-100'
                    onClick={() => changePlan(item)}
                  >
                    {item?.subscriptionPlanId ? 'Change plan' : 'Buy plan'}
                  </ButtonSecondary>
                </div>
                <ButtonTertiarry onClick={() => setDetailedStore(item)}>
                  Show more <FaChevronRight />
                </ButtonTertiarry>
              </div>
            </div>
          </div>
        )
      )
  }

  function _getPlans() {
    return planOptions?.map((item: PlanOptionType) => (
      <div className={`store-container`} onClick={() => _selectPlan(item)}>
        <div className='plan-price'>
          <div className='num-of-listings-dark'>
            <span>{item.name}</span>
          </div>
          <div className='d-flex'>
            <span className='dollar-sign'>$</span>
            <span className='price'>{Math.floor(item.priceInCents / 100)}</span>
            <span className='month'>/month</span>
          </div>
        </div>
        <div className='d-flex'>
          <ButtonSecondary
            fontSize={13}
            paddingx={24}
            paddingy={4}
            className='me-5'
            onClick={() => buyPlan(item)}
          >
            {'Buy plan'}
          </ButtonSecondary>
        </div>
      </div>
    ))
  }

  function _getCanceledStores() {
    return stores
      ?.filter((item) => item?.subscriptionCanceled)
      ?.map((item: StoreType) => (
        <div className='store-wrapper'>
          <h3 className='mb-5 color-grey'>{item.label}</h3>
          <div className={`store-container`}>
            <div className='plan-price'>
              <div className='num-of-listings-canceled'>
                <span>
                  {planOptions.find((plan) => Math.floor(plan.listingsAmount) === item.listings)
                    ?.name || '0 Listings'}
                </span>
              </div>
              <div className='d-flex align-items-start min-w-250px'>
                <span className='dollar-sign'>$</span>
                <span className='price-canceled'>{item?.priceInUsd}</span>
                <span className='month'>/month</span>
              </div>
              <span className='month'>{`Cancels on ${moment(
                new Date(item?.subscriptionCancelAt || new Date())
              ).format('l')}`}</span>
            </div>
            <div className='d-flex'>
              <ButtonTertiarry onClick={() => _reSubscribe(item)}>
                Restore plan
                <img
                  className={`ms-1 ${
                    loading && selectedStore?.storeId === item?.storeId ? 'rotating' : ''
                  }`}
                  src={toAbsoluteUrl('/media/svg/misc/reload.svg')}
                  style={{transform: 'rotate(0deg)'}}
                />
              </ButtonTertiarry>
            </div>
          </div>
        </div>
      ))
  }

  return (
    <Container className='card'>
      <div className='card-header align-items-center p-0 plans-header border-0'>
        <CustomHeader>Subscription Plans</CustomHeader>
      </div>
      {stores?.filter((item) => item?.subscriptionCanceled)?.length ? (
        <CustomHeader>Uncompleted Subscriptions</CustomHeader>
      ) : null}
      <div className='plans-body'>{_getUncompletedSubscriptions()}</div>
      <CustomHeader>My Stores</CustomHeader>
      <div className='plans-body'>{_getStores()}</div>
      <CustomHeader>Add Other Stores</CustomHeader>
      <div className='plans-body'>{_getPlans()}</div>
      {stores?.filter((item) => item?.subscriptionCanceled)?.length ? (
        <CustomHeader>Canceled Subscriptions</CustomHeader>
      ) : null}
      <div className='plans-body'>{_getCanceledStores()}</div>
      <CustomModal width={550} height={350}>
        <div className='modal-container'>
          <div>
            <CustomHeader marginl={0}>Confirmation Needed</CustomHeader>
            <hr className='m-0' />
          </div>
          <div className='info'>
            <span>{`You're going to unsubscribe from ${selectedPlan?.name} plan`}</span>
            <span>{`Note this action will block your access to`}</span>
            <span>{`some sections of the application.`}</span>
            <span className='mt-5'>{`Please confirm your action`}</span>
          </div>
          <div>
            <hr className='m-0' />
            <div className='buttons'>
              <span
                className='btn btn-secondary  fs-4 fw-bold me-5'
                onClick={() => dispatch(toggle())}
              >
                Decline
              </span>
              <ButtonPrimary onClick={_unSubscribe} disabled={loading}>
                Confirm
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </CustomModal>
    </Container>
  )
}

export {Subscriptions}

const Container = styled.div`
  margin-top: 20px;
  width: 100%;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);

  min-width: 910px;
  overflow-x: auto;

  .plans-header {
    margin-right: 24px;
  }

  .plans-body {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
  }

  .modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-inline: 24px;
  }

  .buttons {
    width: 100%;
    margin-block: 24px;
    display: flex;
  }

  .info {
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }

  .dollar-sign,
  .month {
    color: #a1a5bd;
    font-size: 24px;
    font-weight: 500;
    margin-top: 11px;
  }

  .month {
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
  }

  .price,
  .price-canceled {
    color: #3f4254;
    font-size: 50px;
    font-weight: 600;
    padding: 0;
    margin: 0;
  }

  .price-canceled {
    color: #cdcfda;
  }

  .plan-price {
    display: flex;
    align-items: start;
    height: 70px;
  }

  .num-of-listings,
  .num-of-listings-canceled,
  .num-of-listings-dark {
    padding: 8px 16px;
    background-color: #50cd89;
    color: #3f4254;
    font-size: 14px;
    font-weight: 500;
    border-radius: 50px;
    align-self: center;
    margin-right: 200px;
    width: 180px;
    display: flex;
    justify-content: center;
    white-space: nowrap;
  }

  .num-of-listings-dark {
    background-color: #3f4254;
    color: #ffffff;
  }

  .num-of-listings-canceled {
    background-color: #cdcfda;
    color: #ffffff;
  }

  .store-container,
  .detailed-store {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #cdcfda;
    border-radius: 12px;
    padding: 6px 24px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .selected {
    border: 1px solid #009ef7;
    box-shadow: -2px -2px 6px rgba(169, 222, 253, 0.5), 2px 2px 6px rgba(169, 222, 253, 0.5);
  }

  .detailed-store {
    padding-block: 24px;
    display: flex;
    align-items: center;
    border: 1px solid #009ef7;
    height: 350px;
  }

  .store-upper,
  .info-lower {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info-lower {
    align-items: flex-end;
  }

  .store-info,
  .store-plan-info {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .store-info {
    width: 1800px;
    margin-right: 24px;
    box-shadow: -4px -4px 12px rgba(120, 146, 165, 0.05), 4px 4px 12px rgba(120, 146, 165, 0.05);
    border-radius: 12px;
    height: 80%;
    align-self: start;
  }

  .store-plan-info {
    padding: 12px 12px 12px 60px;
    border-left: 1px solid #009ef7;
    width: 100%;
  }

  .next-payment-date,
  .remained-listings,
  .active-products {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
  }

  .next-payment-date {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .store-plan-info-label {
    font-size: 14px;
    font-weight: 500;
    color: #a1a5bd;
  }

  .store-plan-info-value {
    font-size: 18px;
    font-weight: 600;
    color: #3f4254;
  }

  .non-purchased-label {
    font-size: 14px;
    font-weight: 400;
  }

  .duration {
    display: flex;
    flex-direction: column;
  }

  .left-days-date {
    font-size: 13px;
    font-weight: 500;
    color: #a1a5bd;
  }

  .left-days-num {
    font-size: 18px;
    font-weight: 600;
    color: #3f4254;
  }

  .progress-track {
    position: relative;
    width: 300px;
    height: 8px;
    background-color: #ccecfd;
    border-radius: 2px;
    margin-top: 5px;
  }

  .progress-bar {
    position: absolute;
    background-color: #009ef7;
    height: 100%;
    border-radius: 2px;
  }

  .color-grey {
    color: #a1a5bd;
  }

  .rotating {
    animation: circle 1s infinite linear;
  }

  @keyframes circle {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
