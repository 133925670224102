export const FilterTypes = {
  Category: 'Category',
  Brand: 'Brand',
  TitleWord: 'TitleWord',
  TitlePrase: 'TitlePrase',
  Asin: 'Asin',
  Product: 'Product',
}

export const PropertyTypes = {
  Name: 'Name',
  Weight: 'Weight',
  Volume: 'Volume',
  Price: 'Price',
}

export const ListTypes = {
  BlackList: 'BlackList',
  WhiteList: 'WhiteList',
}
