import { FC } from 'react'
import styled from 'styled-components'
import { PricingMarkupType } from '../../../../setup/types/request-data-types/RequestDataTypes'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { ButtonSecondary, CustomHeader } from '../../../components/CustomStyledComponents'
import { StoreSettingsTabs } from './StoreSettings'

type Props = {
  className: string
  data: PricingMarkupType | undefined
  changeTo: (tab: string) => void
}

const PricingTable: FC<Props> = ({ className, data, changeTo }) => {
  function getRows() {
    return data?.rows?.map((item, index) => (
      <tr key={index}>
        <td style={{ width: '270px' }} className='px-5'>
          <div className='d-flex align-items-center'>
            <span className='fw-bold'>FROM </span>
            <span className='mx-5'>{`${item?.startingPoint} ${data?.pricingTable?.inputCurrencyCode}`}</span>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <span>{`+ ${item?.defaultMarkupFlat1} ${data?.pricingTable?.inputCurrencyCode}`}</span>
            <span className='mx-5'>{`+ ${item?.defaultMarkupRate1InPercentage} %`}</span>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <span>{`+ ${item?.minMarkupFlat1} ${data?.pricingTable?.inputCurrencyCode}`}</span>
            <span className='mx-5'>{`+ ${item?.minMarkupRate1InPercentage} %`}</span>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <span>{`+ ${item?.maxMarkupFlat1} ${data?.pricingTable?.inputCurrencyCode}`}</span>
            <span className='mx-5'>{`+ ${item?.maxMarkupRate1InPercentage} %`}</span>
          </div>
        </td>
      </tr>
    ))
  }

  return (
    <Container className={`card ${className}`}>
      <CustomHeader fontSize={18} marginBlock={12}>
        PRICING MARKUP SETTINGS
      </CustomHeader>
      <div className='table-wrapper'>
        {data?.rows?.length ? (
          <table className='table  table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bolder border-0 thead-color'>
                <th className='max-w-100px rounded-start px-5'>Purchase Price</th>
                <th className='min-w-140px'>Default Markup</th>
                <th className='min-w-120px'>Min Markup</th>
                <th className='min-w-100px rounded-end'>Max Markup</th>
              </tr>
            </thead>
            <tbody>{getRows()}</tbody>
          </table>
        ) : (
          <div>
            <span className='empty-info'>No pricing markups were added</span>
            <ButtonSecondary
              fontSize={14}
              onClick={() => changeTo(StoreSettingsTabs.pricing)}
              className='mb-8'
            >
              Add pricing markup settings <img src={toAbsoluteUrl('/media/svg/misc/plus.svg')} />
            </ButtonSecondary>
            <hr className='custom-hr' />
          </div>
        )}
      </div>
    </Container>
  )
}

export { PricingTable }

const Container = styled.div`
  .table-wrapper {
    padding-inline: 24px;
  }

  span {
    font-size: 14px;
    white-space: nowrap;
  }

  .default {
    color: #abadb8;
    font-size: 14px;
  }

  .thead-color {
    color: #3f4254;
    background-color: #e6f5fe;
    font-size: 14px;
  }

  .empty-info {
    display:block;
    font-size: 14px;
    font-weight: 500;
    color: #a1a5bd;
    margin-bottom 24px;
  }

  .custom-hr{
    border-top: 1px dashed #d9dbe3;
  }
`
