import axios from 'axios'
import {OrderFiltersType} from '../../../app/layger/orders/OrderList'
import {PagingType} from '../../types/request-data-types/RequestDataTypes'

export const orderApis = {
  async getPaging(requestData: OrderFiltersType) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/orders`, {
        ...requestData,
        page: (requestData?.page || 0) + 1,
      })
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async getById(orderId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/orders/${orderId}`)
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async getItemsById(orderId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/orders/items/${orderId}`)
      .then((res) => res.data)
      .catch((er) => console.log('error', er))
  },
  async updateNote(orderId: number, note: string) {
    return await axios
      .put(`${process.env.REACT_APP_PROXY}/orders/updateNote?id=${orderId}&text=${note}`)
      .then((res) => res)
      .catch((er) => {
        throw er
      })
  },
}
