import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { ProductForReview, ProductsReview } from './ProductsReview'
import { Pagination } from '../../../components/Pagination'
import { Option } from '../../../components/MultiColumnSelect'
import { ProductFilters, Range } from './WhiteBlackListing'
import styled from 'styled-components'
import { FaChevronLeft } from 'react-icons/fa'
import {
  ButtonPrimary,
  ButtonSecondary,
  CustomHeader,
} from '../../../components/CustomStyledComponents'
import { PagingType } from '../../../../setup/types/request-data-types/RequestDataTypes';
import { FilterTypes, ListTypes, PropertyTypes } from '../../filters/importProductsFilters'
import { importProductsApis } from '../../../../setup/apis/product/importProductsApis'
import * as uuid from 'uuid'
import { triggerAlert } from '../../../components/Alert'

type Props = {}

type SearchFilterType = {
  page?: number | undefined
  pageSize?: number | undefined
  storeId?: number | undefined
  title?: string | undefined
  priceFrom?: number | undefined
  priceTo?: number | undefined
  weightFrom?: number | undefined
  weightTo?: number | undefined
  volumeFrom?: number | undefined
  volumeTo?: number | undefined
  categoryIds?: string[] | undefined
}

const initialWeightRange: Range = { from: '', to: '' }
const initialVolumeRange: Range = { from: '', to: '' }
const initialPriceRange: Range = { from: '', to: '' }

const Review: FC<Props> = () => {
  const [totalCount, setTotalCount] = useState<number>(0)
  const [data, setData] = useState<ProductForReview[]>([])
  const [title, setTitle] = useState<string>('')
  const [categories, setCategories] = useState<Option[]>([])
  const [brands, setBrands] = useState<Option[]>([])
  const [weightRange, setWeightRange] = useState<Range>(initialWeightRange)
  const [volumeRange, setVolumeRange] = useState<Range>(initialVolumeRange)
  const [priceRange, setPriceRange] = useState<Range>(initialPriceRange)
  const history = useHistory<ProductFilters>()
  const [loading, setLoading] = useState<boolean>(false)
  const [importLoading, setImportLoading] = useState<boolean>(false)
  const [removeLoading, setRemoveLoading] = useState<boolean>(false)
  const state = useRef<ProductFilters>(history.location.state)
  const [pagingData, setPagingData] = useState<PagingType>({ pageNumber: 0, pageSize: 50 })
  const rangeRef = useRef<any>()
  const titleRef = useRef<any>()

  useEffect(() => {
    setData(state?.current?.products || [])
    setCategories(state?.current?.whiteList?.categories || [])
    setBrands(state?.current?.whiteList?.brands || [])
    _getProductsForImport()
  }, [])

  async function _getProductsForImport(searchData?: SearchFilterType) {
    const requestData = {
      page: searchData?.page ?? pagingData?.pageNumber,
      pageSize: searchData?.pageSize ?? pagingData?.pageSize,
      storeId: state?.current?.store?.storeId,
      title: searchData?.title ?? title,
      priceFrom: searchData?.priceFrom ?? priceRange?.from,
      priceTo: searchData?.priceTo ?? priceRange?.to,
      weightFrom: searchData?.weightFrom ?? weightRange?.from,
      weightTo: searchData?.weightTo ?? weightRange?.to,
      volumeFrom: searchData?.volumeFrom ?? volumeRange?.from,
      volumeTo: searchData?.volumeTo ?? volumeRange?.to,
      categoryIds:
        searchData?.categoryIds ||
        categories.filter((item) => item.selected).map((item) => item.id),
    }
    try {
      setLoading(true)
      const { data, totalCount } = await importProductsApis.getReviewProducts(requestData)
      setData(data.map((item: any) => ({ ...item, id: uuid.v1().toString() })))
      setTotalCount(totalCount)
    } catch (er) {
      console.log('error', er)
    } finally {
      setLoading(false)
    }
  }

  async function _remove(item: ProductForReview) {
    if (removeLoading) return
    try {
      setRemoveLoading(true)
      const response = await importProductsApis.addStringFilter([
        {
          storeId: state?.current.store.storeId,
          listType: ListTypes.BlackList,
          filterType: FilterTypes.Asin,
          value: item?.asin,
        },
      ])
      state.current.blackList.asins = [
        ...state?.current?.blackList?.asins,
        ...response?.map((item: any) => ({
          ...item,
          id: item?.value,
          title: item?.product?.title,
          numberOfItems: item?.productCount,
          asin: item?.value,
          updateDate: new Date(item?.updateDate),
        })),
      ]
      _getProductsForImport({ page: 0 })
      triggerAlert('Product was blacklisted.')
    } catch (e) {
      console.log('error', e)
    } finally {
      setRemoveLoading(false)
    }
  }

  async function _removeAll() {
    if (removeLoading) return
    try {
      setRemoveLoading(true)
      const requestData = data
        .filter((item) => item.selected)
        .map((item) => ({
          storeId: state?.current.store.storeId,
          listType: ListTypes.BlackList,
          filterType: FilterTypes.Asin,
          value: item?.asin,
        }))
      if (!requestData.length) return
      const response = await importProductsApis.addStringFilter(requestData)
      state.current.blackList.asins = [
        ...state?.current?.blackList?.asins,
        ...response?.map((item: any) => ({
          ...item,
          id: item?.value,
          title: item?.product?.title,
          numberOfItems: item?.productCount,
          asin: item?.value,
          updateDate: new Date(item?.updateDate),
        })),
      ]
      _getProductsForImport({ page: 0 })
      triggerAlert('Product was blacklisted.')
    } catch (e) {
      console.log('error', e)
    } finally {
      setRemoveLoading(false)
    }
  }

  function _onPageChange({ selected }: any) {
    _getProductsForImport({ page: selected })
    setPagingData((prevData) => ({ ...prevData, pageNumber: selected }))
  }

  function prevStep() {
    history.push('/products/white-listing', { ...state.current, products: data })
  }

  function _manageData(item: ProductForReview) {
    setData((prevData) =>
      prevData.map((product: ProductForReview) => ({
        ...product,
        selected: product.id === item.id ? !item.selected : product.selected,
      }))
    )
  }

  function _manageAllData() {
    const everyItem = data.every((item) => item.selected)
    if (everyItem) {
      setData((prevData) => prevData.map((item) => ({ ...item, selected: false })))
    } else {
      setData((prevData) => prevData.map((item) => ({ ...item, selected: true })))
    }
  }

  function _manageTitle(text: string) {
    setTitle(text)
    if (titleRef.current) clearTimeout(titleRef.current)
    titleRef.current = setTimeout(() => {
      _getProductsForImport({ page: 0, title: text })
    }, 1000)
  }

  function _manageOptions(array: Option[], label: string) {
    switch (label) {
      case FilterTypes.Category:
        _getProductsForImport({
          page: 0,
          categoryIds: array.filter((item) => item.selected).map((item) => item.id),
        })
        setCategories(array)
        return
      case FilterTypes.Brand:
        setBrands(array)
        return
    }
  }

  function _manageAllOptions(label: string) {
    switch (label) {
      case FilterTypes.Category:
        _getProductsForImport({ page: 0, categoryIds: [] })
        setCategories((prevData) => prevData.map((cat) => ({ ...cat, selected: false })))
        return
      case FilterTypes.Brand:
        setBrands((prevData) => prevData.map((cat) => ({ ...cat, selected: false })))
        return
    }
  }

  async function _manageRanges(obj: Range, label: string) {
    switch (label) {
      case PropertyTypes.Weight:
        setWeightRange(obj)
        break
      case PropertyTypes.Volume:
        setVolumeRange(obj)
        break
      case PropertyTypes.Price:
        setPriceRange(obj)
        break
    }
    if (rangeRef.current) clearTimeout(rangeRef.current)
    rangeRef.current = setTimeout(() => {
      switch (label) {
        case PropertyTypes.Weight:
          _getProductsForImport({ page: 0, weightFrom: obj?.from || 0, weightTo: obj?.to || 0 })
          return
        case PropertyTypes.Volume:
          _getProductsForImport({ page: 0, volumeFrom: obj?.from || 0, volumeTo: obj?.to || 0 })
          return
        case PropertyTypes.Price:
          _getProductsForImport({ page: 0, priceFrom: obj?.from || 0, priceTo: obj?.to || 0 })
          return
      }
    }, 1000)
  }

  async function _condfirmImport() {
    try {
      setImportLoading(true)
      const response = await importProductsApis?.confirmImport(state?.current?.store?.storeId)
      if (response?.status === 200) {
        triggerAlert('Products were successfully imported!')
      }
    } catch (e: any) {
      if (e?.response?.status === 400) {
        triggerAlert(e?.response?.data?.Message, 'error')
        return
      }
    } finally {
      setImportLoading(false)
    }
  }

  return (
    <Container className='card'>
      <div className='d-flex align-items-center justify-content-between'>
        <CustomHeader>Step 3: Review</CustomHeader>
        <ButtonSecondary className='button' onClick={prevStep}>
          <FaChevronLeft /> {' Previous Step'}
        </ButtonSecondary>
      </div>
      <hr className='my-0' />
      <ProductsReview
        data={data}
        remove={_remove}
        removeAll={_removeAll}
        title={title}
        categories={categories}
        brands={brands}
        weightRange={weightRange}
        volumeRange={volumeRange}
        priceRange={priceRange}
        manageTitle={_manageTitle}
        manageOptions={_manageOptions}
        manageAllOptions={_manageAllOptions}
        manageRanges={_manageRanges}
        manageData={_manageData}
        manageAllData={_manageAllData}
        loading={loading}
      />
      <div className='px-5 mb-8'>
        <Pagination
          currentPage={pagingData.pageNumber}
          pageSize={pagingData.pageSize}
          totalCount={totalCount}
          onPageChange={_onPageChange}
          onPageSizeChange={() => { }}
        />
      </div>
      <hr className='mt-0' />
      <div className='custom-footer'>
        <h2>{`TOTAL AMOUNT OF PRODUCTS:  ${totalCount}`}</h2>
        <ButtonPrimary
          className='mt-2'
          onClick={_condfirmImport}
          disabled={!data.length || importLoading}
        >
          Confirm Import
        </ButtonPrimary>
      </div>
    </Container>
  )
}

export { Review }

const Container = styled.div`
  margin-bottom: 48px;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  min-width: 1200px;
  border-radius: 8px;

  .button {
    background-color: #cdedff;
    color: #009ef7;
    margin-right: 24px;
  }

  .custom-footer {
    padding: 0 0 24px 24px;
  }
`
