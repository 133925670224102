import React, { useEffect, useState } from 'react';
import Input from '../../../components/Input'
import moment from 'moment'
import styled from 'styled-components'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import { DateTimePicker } from '../../../components/DateTimePicker'
import 'react-datepicker/dist/react-datepicker.css'
import MaskedInput from 'react-text-mask'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { storeSettingsApis } from '../../../../setup/apis/store/storeSettingsApis'
import { StorePropertiesType } from '../../../../setup/types/request-data-types/RequestDataTypes'
import { initialData } from '../../settings/account-settings/AccountSettings'
import { triggerAlert } from '../../../components/Alert'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { capitalize } from '../../../utils/inputFieldUtils'

const initialValues: StorePropertiesType = {
  repricerRuleName: '',
  fbmLeadTime: '',
  fbaLeadTime: '',
  pauseLoweringListingPricingFrom: new Date(),
  pauseLoweringListingPricingUntil: new Date(new Date().getTime() + 60000),
  pauseLoweringListingPricing: false,
}

type Props = {
  storeId: number
}

const AdditionalSettings: React.FC<Props> = ({ storeId }) => {
  const [data, setData] = useState<StorePropertiesType>(initialValues)
  const [loading, setLoading] = useState<boolean>(false)
  const notify = () =>
    toast('Date range is invalid', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    })

  useEffect(() => {
    getData()
  }, [storeId])

  async function getData() {
    const response = await storeSettingsApis.getStoreProperties(storeId)
    setData(
      {
        ...response,
        repricerRuleName: response?.repricerRuleName || '',
        pauseLoweringListingPricingFrom:
          response?.pauseLoweringListingPricingFrom ||
          initialValues.pauseLoweringListingPricingFrom,
        pauseLoweringListingPricingUntil:
          response?.pauseLoweringListingPricingUntil ||
          initialValues.pauseLoweringListingPricingUntil,
      } || initialData
    )
  }

  function _onSubmit(e: any) {
    e.preventDefault()
  }

  function _onChange({ target }: any) {
    setData((prev) => ({ ...prev, [target.name]: target.value }))
  }

  function _onChangeDate(date: any, name: keyof StorePropertiesType) {
    if (
      (name === 'pauseLoweringListingPricingUntil' &&
        new Date(data.pauseLoweringListingPricingFrom || new Date()) >= new Date(date)) ||
      (name === 'pauseLoweringListingPricingFrom' &&
        new Date(date) >= new Date(data.pauseLoweringListingPricingUntil || new Date()))
    ) {
      date = data?.[name]
    }
    setData((prev) => ({ ...prev, [name]: date }))
  }

  function _onChangeChecked() {
    setData((prev) => ({
      ...prev,
      pauseLoweringListingPricing: !prev.pauseLoweringListingPricing,
    }))
  }

  async function saveChanges() {
    try {
      setLoading(true)
      const response = await storeSettingsApis.updateStoreProperties(storeId, {
        ...data,
        repricerRuleName: capitalize(data.repricerRuleName),
        pauseLoweringListingPricingFrom:
          data?.pauseLoweringListingPricingFrom || initialValues.pauseLoweringListingPricingFrom,
        pauseLoweringListingPricingUntil:
          data?.pauseLoweringListingPricingUntil || initialValues.pauseLoweringListingPricingUntil,
      })
      if (response?.status === 200) {
        triggerAlert('Changes saved successfully!')
      }
    } catch (e) {
      console.log('error', e)
      triggerAlert('Something went wrong...')
    } finally {
      setLoading(false)
    }
  }

  function isInValidForm() {
    return Object.values(data).some((item) => typeof item !== 'boolean' && !item)
  }

  function isVisible(name: keyof StorePropertiesType) {
    return data?.[name]
  }

  function clearInput(name: keyof StorePropertiesType) {
    setData((prev) => ({ ...prev, [name]: '' }))
  }

  return (
    <Container className='card mb-5 mb-xl-10'>
      <CustomHeader>Additional Settings</CustomHeader>
      <div>
        <form onSubmit={_onSubmit} noValidate className='settings-container'>
          <div>
            <div className='setting-container'>
              <div className='info-label'>
                <span>Repricer rule name</span>
              </div>
              <div className='input-container'>
                <Input
                  id='name'
                  name='repricerRuleName'
                  type='text'
                  className='input-value input-cap'
                  placeholder='Name'
                  value={data?.repricerRuleName}
                  onChange={_onChange}
                />
                {isVisible('repricerRuleName') ? (
                  <div className='icon' onClick={() => clearInput('repricerRuleName')}>
                    <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className='setting-container'>
              <div className='info-label'>Lead time for FBM offers</div>
              <div className='input-container'>
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/]}
                  guide={false}
                  id='fbmLeadTime'
                  name='fbmLeadTime'
                  type='text'
                  className='input-value'
                  placeholder='Number Of Days'
                  value={data?.fbmLeadTime}
                  onChange={_onChange}
                  min={0}
                />
                {isVisible('fbmLeadTime') ? (
                  <div className='icon' onClick={() => clearInput('fbmLeadTime')}>
                    <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className='setting-container'>
              <div className='info-label'>Lead time for FBA offers</div>
              <div className='input-container'>
                <MaskedInput
                  mask={[/\d/, /\d/, /\d/]}
                  guide={false}
                  id='fbaLeadTime'
                  name='fbaLeadTime'
                  type='text'
                  className='input-value'
                  placeholder='Number Of Days'
                  value={data?.fbaLeadTime}
                  onChange={_onChange}
                  min={0}
                />
                {isVisible('fbaLeadTime') ? (
                  <div className='icon' onClick={() => clearInput('fbaLeadTime')}>
                    <img src={toAbsoluteUrl(`/media/svg/misc/close.svg`)} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className='setting-container'>
              <div className='info-label'>
                <span>Pause lowering listing pricing</span>
              </div>
              <div className='input-container'>
                <div className='d-flex align-items-center custom-date-container'>
                  <DateTimePicker
                    className='input-value'
                    value={moment(
                      new Date(data?.pauseLoweringListingPricingFrom || new Date())
                    ).format('DD/MM/YYYY hh:mm A')}
                    selected={new Date(data?.pauseLoweringListingPricingFrom || new Date())}
                    onChange={(date) => _onChangeDate(date, 'pauseLoweringListingPricingFrom')}
                    showTimeSelect
                  />
                </div>
                <div className='d-flex align-items-center custom-date-container'>
                  <DateTimePicker
                    className='input-value'
                    value={moment(
                      new Date(data?.pauseLoweringListingPricingUntil || new Date())
                    ).format('DD/MM/YYYY hh:mm A')}
                    selected={new Date(data?.pauseLoweringListingPricingUntil || new Date())}
                    onChange={(date) => _onChangeDate(date, 'pauseLoweringListingPricingUntil')}
                    showTimeSelect
                  />
                </div>
                <div className='d-flex justify-content-end ms-2'>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <Input
                      name='active'
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      id='allowmarketing'
                      checked={data?.pauseLoweringListingPricing}
                      onChange={_onChangeChecked}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr className='custom-hr' />
        <div className='apply-button'>
          <ButtonPrimary disabled={loading} onClick={saveChanges}>
            Apply changes
          </ButtonPrimary>
        </div>
      </div>
      <ToastContainer />
    </Container>
  );
}

export { AdditionalSettings }

const Container = styled.div`
  .custom-hr {
    margin: 0;
  }

  .settings-container {
    display: flex;
    padding: 24px;
  }
  .apply-button {
    margin: 24px;
  }

  .info-label {
    font-size: 14px;
    font-weight: 600;
    min-width: 400px;
    margin-bottom: 4px;
  }

  .setting-container {
    padding-block: 12px;
    width: 500px;
  }

  .setting-container {
    display: flex;
    flex-direction: column;
  }

  .input-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 750px;
  }

  .icon {
    position: absolute;
    top: 13px;
    right: 12px;
    cursor: pointer;
  }

  .custom-date-container {
    margin-right: 24px;
    width: 700px;
  }

  .input-value {
    border: 2px solid #e3e4ea;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
  }

  .input-value:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 2px #c0e7fd;
  }

  .input-cap {
    text-transform: capitalize;
    
    //placeholder
    ::-webkit-input-placeholder {
      text-transform: none;
    }
    :-moz-placeholder { 
        text-transform: none;
    }
    ::-moz-placeholder { 
        text-transform: none;
    }
    :-ms-input-placeholder { 
        text-transform: none;
    }
    ::placeholder { 
        text-transform: none;
    }
  }
`
