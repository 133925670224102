import {FC, useEffect, useRef, useState} from 'react'
import {FaChevronLeft, FaChevronRight, FaEye, FaEyeSlash} from 'react-icons/fa'
import {useHistory} from 'react-router'
import styled from 'styled-components'
import {ButtonSecondary, CustomHeader} from '../../components/CustomStyledComponents'
import Select from '../../components/Select'
import {RegistrationType} from './SubscriptionPlan'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {initialCard} from './PaymentCard'
import {useSelector} from 'react-redux'
import {accountApis} from '../../../setup/apis/account/accountApis'
import {CountryType} from '../../../setup/types/response-data-types/ResponseDataTypes'
import {DetailsType, initialDetails} from '../account-creation/AccountCreation'
import {triggerAlert} from '../../components/Alert'
import {countrySelector} from '../../modules/selectors/reduxSelectors'

const phoneRegExp = /^^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

const regSchema = Yup.object().shape({
  username: Yup.string().trim().required('username is required'),
  firstName: Yup.string().trim().required('First name is required'),
  lastName: Yup.string().trim().required('Last name is required'),
  email: Yup.string()
    .trim()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  // phone: Yup.string().trim()
  //   .required('Phone number is required')
  //   .matches(phoneRegExp, 'Invalid phone number'),
  password: Yup.string()
    .trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .trim()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .trim()
        .oneOf([Yup.ref('password')], "Passwords don't match"),
    }),
})

const AccountDetails: FC = () => {
  const history = useHistory<RegistrationType>()
  const state = history.location?.state
  const data = useRef<DetailsType>(initialDetails)
  const countryOptions = useSelector(countrySelector)
  const [country, setCountry] = useState<CountryType>({countryId: -1, label: ''})
  const [userCreated, setUserCreated] = useState<boolean>(false)
  const [isVisiblePass, setIsVisiblePass] = useState<boolean>()
  const [isVisibleConfPass, SetIsVisibleConfPass] = useState<boolean>()
  const formik = useFormik({
    initialValues: data.current,
    validationSchema: regSchema,
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting}) => {
      console.log('submitted')
    },
  })

  function _isValidForm() {
    return (
      Object.values(formik.values).every((item) => item.toString().length && item !== -1) &&
      Object.values(formik.errors).length === 0
    )
  }

  function _manageCountry(option: CountryType) {
    formik.values.countryId = option.countryId
    setCountry(option)
  }

  useEffect(() => {
    if (state?.details) {
      data.current = state.details
    }
  }, [])

  function _previousPage() {
    history.push('/registration/subscription-plan', {
      plan: state.plan,
      details: formik.values,
      card: state?.card || initialCard,
    })
  }

  function _nextPage() {
    history.push('/registration/order-summary', {
      plan: state.plan,
      details: formik.values,
      card: state?.card || initialCard,
    })
  }

  async function _createAccount() {
    // try {
    //   if (!_isValidForm()) return
    //   const response = await accountApis.register(formik.values)
    //   if (response?.status === 200) {
    //     triggerAlert('Account created successfully')
    //   }
    // } catch (err: any) {
    //   if (err?.response?.status === 400) {
    //     triggerAlert(
    //       `There are some errors:${err?.response?.data?.errors.map(
    //         (item: any) => `\n${item?.description}`
    //       )}`.replaceAll(',', '')
    //     )
    //   }
    // }
  }

  return (
    <Container className='card'>
      <div className='card-header align-items-center p-0'>
        <CustomHeader>Account Details</CustomHeader>
        <div className='header-buttons'>
          <ButtonSecondary onClick={() => _previousPage()}>
            <FaChevronLeft /> Previous Step
          </ButtonSecondary>
          <ButtonSecondary onClick={_createAccount} disabled={!_isValidForm()} className='ms-5'>
            Next Step <FaChevronRight />
          </ButtonSecondary>
        </div>
      </div>
      <hr className='m-0' />
      <div className='details-body'>
        {/* <div className='plan-info'>
          <span className='plan-label'>SELECTED SUBSCRIPTION PLAN</span>
          <div className='plan-title-date-wrapper'>
            <span className='plan-title'>{state?.plan.title}</span>
            <span className='plan-date'>{`Free till ${moment(new Date()).format('l')}`}</span>
          </div>
        </div> */}
        <div className='form-row'>
          <div className='form-item'>
            <label className='input-label'>First Name</label>
            <input
              type='text'
              placeholder='Insert your first name'
              autoComplete='off'
              {...formik.getFieldProps('firstName')}
              className={clsx(
                'form-control form-control-lg  input-value',
                {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                {
                  'is-valid': formik.touched.firstName && !formik.errors.firstName,
                }
              )}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstName}</span>
                </div>
              </div>
            )}
          </div>
          <div className='form-item'>
            <label className='input-label'>Last Name</label>
            <input
              type='text'
              autoComplete='off'
              placeholder='Insert your last name'
              {...formik.getFieldProps('lastName')}
              className={clsx(
                'form-control form-control-lg  input-value',
                {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                {
                  'is-valid': formik.touched.lastName && !formik.errors.lastName,
                }
              )}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='form-row'>
          <div className='form-item'>
            <label className='input-label'>Email</label>
            <input
              type='email'
              autoComplete='off'
              pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
              required
              placeholder='Insert your email'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg  input-value',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          <div className='form-item'>
            <label className='input-label'>Country</label>
            <Select
              options={countryOptions}
              withRs
              value={countryOptions.find((item) => item.countryId === country.countryId)}
              onChange={(option) => _manageCountry(option)}
              className={clsx(
                'form-select form-select-solid bold-input input-value',
                {'is-invalid': formik.touched.countryId && formik.errors.countryId},
                {
                  'is-valid': formik.touched.countryId && !formik.errors.countryId,
                }
              )}
              placeholder='Select country...'
            />
            {formik.touched.countryId && formik.errors.countryId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.countryId}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='form-row'>
          <div className='form-item'>
            <label className='input-label'>Password</label>
            <div className='icon-container'>
              <input
                type={isVisiblePass ? 'text' : 'password'}
                autoComplete='off'
                placeholder='Insert a password'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg  input-value',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <div className='icon' onClick={() => setIsVisiblePass((prev) => !prev)}>
                {isVisiblePass ? <FaEyeSlash fontSize={25} /> : <FaEye fontSize={25} />}
              </div>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div className='form-item'>
            <label className='input-label'>Confirm Password</label>
            <div className='icon-container'>
              <input
                type={isVisibleConfPass ? 'text' : 'password'}
                autoComplete='off'
                placeholder='Confirm your password'
                {...formik.getFieldProps('confirmPassword')}
                className={clsx(
                  'form-control form-control-lg  input-value',
                  {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
                  {
                    'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                  }
                )}
              />
              <div className='icon' onClick={() => SetIsVisibleConfPass((prev) => !prev)}>
                {isVisibleConfPass ? <FaEyeSlash fontSize={25} /> : <FaEye fontSize={25} />}
              </div>
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='form-row'>
          <div className='form-item-single'>
            <label className='input-label'>Username</label>
            <input
              type='username'
              autoComplete='off'
              pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
              required
              placeholder='Insert your username'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-lg  input-value',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>
          <div className='form-item-single' />
        </div>
      </div>
    </Container>
  )
}

export {AccountDetails}

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  min-width: 800px;
  // overflow-x: auto;
  // padding-bottom: 36px;

  .header-buttons {
    padding: 0 24px;
    display: flex;

    div {
      margin-left: 12px;
    }
  }

  .details-body {
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content: start;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
  }

  .plan-label {
    font-size: 16px;
    font-weight: 700;
    color: #40b6f9;
  }

  .plan-title {
    font-size: 24px;
    font-weight: 700;
    align-self: end;
  }

  .plan-title-date-wrapper {
    margin-top: 12px;
    display: flex;
    align-items: center;
    height: 50px;
  }

  .plan-date {
    margin: 0 0 0 10px;
    background-color: #e6f5fe;
    padding: 6px 24px;
    display: flex;
    border-radius: 30px;
    align-items: center;
    color: #005f94;
    font-size: 12px;
    font-weight: 600;
    align-self: start;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    width: 100%;
  }

  .form-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .form-item,
  .form-item-single {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-right: 60px;
    padding: 6px 0;
  }

  .form-item-single {
    width: 100%;
  }

  .input-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .input-value {
    border: 1px solid #cdcfda;
  }

  .input-value:focus {
    outline: none;
    box-shadow: 0 0 1px 3px #c0e7fd;
  }

  .create-account {
    margin: 24px;
  }

  .icon-container {
    position: relative;
  }

  .icon {
    position: absolute;
    top: 11px;
    right: 45px;
    cursor: pointer;
  }
`
