/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { RiSettings3Fill } from 'react-icons/ri'
import { useHistory } from 'react-router'
import { StoreType } from '../../../setup/types/response-data-types/ResponseDataTypes'
import { KTSVG } from '../../../_metronic/helpers'
import { ButtonTertiarry } from '../../components/CustomStyledComponents'
type Props = {
  className: string
  data: StoreType
}

const Store: React.FC<Props> = ({ className, data }) => {
  const history = useHistory()

  function _goToSettings() {
    history.push('/stores/store-settings', { storeId: data.storeId, from: 'stores' })
  }
  const amazonLink = 'https://www.amazon.com/'
  const bgColor = 'primary'
  const color = 'white'

  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${bgColor} `}>
          <div className={`d-flex text-center flex-column text-${color} pt-8`}>
            <span className='fw-bolder fs-2x pt-5'>{data?.label}</span>
          </div>
        </div>
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
          style={{ marginTop: '-100px' }}
        >
          <div className='d-flex align-items-center mb-6 justify-content-between'>
            <div className='symbol symbol-45px w-40px me-2'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotone/Shopping/Cart1.svg' className='svg-icon-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 w-125px text-nowrap'>
                <span className='fs-5 text-gray-800 fw-bolder'>Marketplace</span>
                <div className='text-gray-400 fw-bold fs-7'>{data?.marketplaceCode}</div>
              </div>
              <div className='symbol symbol-45px w-40px me-2'>
                <span className='symbol-label bg-lighten'>
                  <KTSVG
                    path='/media/icons/duotone/Interface/Calendar.svg'
                    className='svg-icon-1'
                  />
                </span>
              </div>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 fw-bolder'>Created on</span>
                <div className='text-gray-400 fw-bold fs-7'>
                  {moment(data.createdOn).format('l')}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-6 justify-content-between'>
            <div className='symbol symbol-45px w-40px me-2'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotone/Home/Globe.svg' className='svg-icon-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 w-125px text-nowrap'>
                <span className='fs-5 text-gray-800 fw-bolder'>Country</span>
                <div className='text-gray-400 fw-bold fs-7'>{data.country}</div>
              </div>
              <div className='symbol symbol-45px w-40px me-2'>
                <span className='symbol-label bg-lighten'>
                  <KTSVG path='/media/icons/duotone/General/Clipboard.svg' className='svg-icon-1' />
                </span>
              </div>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 fw-bolder'>Active Products</span>
                <div className='text-gray-400 fw-bold fs-7'>{data.activeProductCount}</div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-2'>
              <span className='symbol-label bg-lighten'>
                <KTSVG path='/media/icons/duotone/Shopping/Dollar.svg' className='svg-icon-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 fw-bolder'>Currency</span>
                <div className='text-gray-400 fw-bold fs-7'>{data?.currencyCode}</div>
              </div>
            </div>
          </div>
          <div className='buttons'>
            <ButtonTertiarry onClick={_goToSettings}>
              Store settings <RiSettings3Fill className='icon' />
            </ButtonTertiarry>
            <a href={amazonLink} target='_blank' className='action-button'>
              Go to Amazon store <FaChevronRight fontSize={12} className='icon' />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
  // return (
  //   <div className={`card ${className}`}>
  //     <div className='card-body p-0'>
  //       <div className={`px-9 pt-7 card-rounded h-275px w-100 upper-part`}>
  //         <div className={`d-flex text-center flex-column text-black`}>
  //           <span className='fw-bolder fs-2x pt-6'>{data.title}</span>
  //         </div>
  //       </div>
  //       <div className='shadow card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white data-container'>
  //         <div className='d-flex align-items-center mb-6'>
  //           <div className='mb-1 pe-3 flex-grow-1 d-flex align-items-center'>
  //             <div className='bulletos' />
  //             <span className='prop-name'>Marketplace</span>
  //           </div>
  //           <div className='d-flex align-items-center'>
  //             <div className='fw-bolder fs-5 text-gray-800 pe-1'>{`(${data.marketPlace})`}</div>
  //           </div>
  //         </div>
  //         <div className='d-flex align-items-center mb-6'>
  //           <div className='mb-1 pe-3 flex-grow-1 d-flex align-items-center'>
  //             <div className='bulletos' />
  //             <span className='prop-name'>Country</span>
  //           </div>
  //           <div className='d-flex align-items-center'>
  //             <div className='fw-bolder fs-5 text-gray-800 pe-1'>{`(${data.country})`}</div>
  //           </div>
  //         </div>
  //         <div className='d-flex align-items-center mb-6'>
  //           <div className='mb-1 pe-3 flex-grow-1 d-flex align-items-center'>
  //             <div className='bulletos' />
  //             <span className='prop-name'>Currency</span>
  //           </div>
  //           <div className='d-flex align-items-center'>
  //             <div className='fw-bolder fs-5 text-gray-800 pe-1'>{`(${data.currency})`}</div>
  //           </div>
  //         </div>
  //         <div className='d-flex align-items-center mb-6'>
  //           <div className='mb-1 pe-3 flex-grow-1 d-flex align-items-center'>
  //             <div className='bulletos' />
  //             <span className='prop-name'>Created on</span>
  //           </div>
  //           <div className='d-flex align-items-center'>
  //             <div className='fw-bolder fs-5 text-gray-800 pe-1'>
  //               {moment(data.createdDate).format('l')}
  //             </div>
  //           </div>
  //         </div>
  //         <div className='d-flex align-items-center'>
  //           <div className='mb-1 pe-3 flex-grow-1 d-flex align-items-center'>
  //             <div className='bulletos' />
  //             <span className='prop-name'>Active Products</span>
  //           </div>
  //           <div className='d-flex align-items-center'>
  //             <div className='fw-bolder fs-5 text-gray-800 pe-1'>{data.numOfActiveProducts}</div>
  //           </div>
  //         </div>
  //         <div className='buttons'>
  //           <span className='action-button' onClick={_goToSettings}>
  //             Store settings <RiSettings3Fill className='icon' />
  //           </span>
  //           <a href={amazonLink} target='_blank' className='action-button'>
  //             Go to Amazon store <FaChevronRight fontSize={12} className='icon' />
  //           </span>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export { Store }
