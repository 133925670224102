import { FC } from 'react';
import { Store } from './Store'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { CustomHeader } from '../../components/CustomStyledComponents';
import { StoreType } from '../../../setup/types/response-data-types/ResponseDataTypes'
import { useSelector } from 'react-redux'
import { activeStoreSelector } from '../../modules/selectors/reduxSelectors'

type Props = {}

const StoreList: FC<Props> = () => {
  const data = useSelector(activeStoreSelector)
  const history = useHistory()

  function getList() {
    if (!data.length) {
      return (
        <div className='no-stores text-nowrap'>Oops... It looks like you got no stores yet</div>
      )
    }
    return data?.map((store: StoreType, i: number) => (
      <Store key={i} data={store} className='card-xl-stretch mb-xl-8 store' />
    ))
  }

  function _goToCreateStore() {
    history.push('/stores/create-store', { from: 'stores' })
  }

  return (
    <Container className='card'>
      <div className='d-flex align-items-center justify-content-between'>
        <CustomHeader>Store List</CustomHeader>
        {/* <div className='button-create'>
          <ButtonPrimary onClick={() => _goToCreateStore()}>Create New Store</ButtonPrimary>
        </div> */}
      </div>
      <hr className='m-0' />
      <div className='card-body px-0 pt-1 list-wrapper'>
        <div className='stores-wrapper'>{getList()}</div>
      </div>
    </Container>
  )
}

export default StoreList

const Container = styled.div`
  min-width: 1260px;
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  min-height: 80vh;
  margin-bottom:48px;

  .stores-wrapper {
    margin: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  .list-wrapper {
    position: relative;
  }

  .no-stores {
    position: absolute;
    font-size: 32px;
    font-weight: 700;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .store {
    width: 40%;
    margin: 20px 60px;

    .data-container {
      margin-top: -150px;
    }

    .upper-part {
      background-color: #d9f1fe;
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 50px;
    }

    .action-button {
      padding: 5px 20px;
      background-color: #e4e6ef;
      color: #3f4254;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }

    .action-button:hover {
      background-color: #e4e6ef;
      color: #3f4254;
    }

    .action-button:active {
      background-color: #e4e6ef;
      color: #3f4254;
    }

    .prop-name {
      font-size: 16px;
      color: #707070;
    }
  }

  .button-create {
    margin-inline: 24px;
  }

  .icon {
    margin-bottom: 2px;
  }

  .bulletos {
    width: 5px;
    height: 5px;
    background-color: #707070;
    border-radius: 50%;
    margin-right: 3px;
  }
`
