import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`

export const CustomHeader = styled.label<any>(
  ({fontSize = 24, margint = 24, marginr = 24, marginb = 24, marginl = 24}) => `
  margin: ${margint}px ${marginr}px ${marginb}px ${marginl}px;
  font-size: ${fontSize}px;
  font-weight: 600;
`
)
export const ButtonSecondary = styled.button<any>(
  ({
    disabled,
    color = '#009ef7',
    bgColor = '#ccecfd',
    bordertr = 5,
    bordertl = 5,
    borderbr = 5,
    borderbl = 5,
    grow = 0,
    fontSize = 16,
    paddingy = 10,
    paddingx = 20,
    fontWeight = 600,
  }) => `
  padding:${paddingy}px ${paddingx}px;
  border-top-right-radius:${bordertr}px;
  border-top-left-radius:${bordertl}px;
  border-bottom-right-radius:${borderbr}px;
  border-bottom-left-radius:${borderbl}px;
  border:none;
  background-color:${disabled ? '#eaf6ff' : bgColor};
  color:${disabled ? '#c0e7fd' : color};
  font-size:${fontSize}px;
  font-weight:${fontWeight};
  flex-grow:${grow};
  justify-content:center;
  text-align:center;
  align-items:center;
  cursor:pointer;
  
  ${
    !disabled &&
    `:hover {
    background-color:#f3faff;
    color:${color};
  }

  :active {
    background-color:${bgColor};
    color:${color};
  }`
  }
`
)

export const FileUploadButton = styled.label<any>(
  ({
    disabled,
    color = '#009ef7',
    bgColor = '#ccecfd',
    bordertr = 5,
    bordertl = 5,
    borderbr = 5,
    borderbl = 5,
    grow = 0,
    fontSize = 16,
    paddingy = 5,
    paddingx = 20,
    fontWeight = 600,
  }) => `
  padding:${paddingy}px ${paddingx}px;
  border-top-right-radius:${bordertr}px;
  border-top-left-radius:${bordertl}px;
  border-bottom-right-radius:${borderbr}px;
  border-bottom-left-radius:${borderbl}px;
  border:none;
  background-color:${disabled ? '#eaf6ff' : bgColor};
  color:${disabled ? '#c0e7fd' : color};
  font-size:${fontSize}px;
  font-weight:${fontWeight};
  flex-grow:${grow};
  justify-content:center;
  text-align:center;
  align-items:center;
  cursor:pointer;
  white-space: nowrap;
  display:flex;
  
  ${
    !disabled &&
    `:hover {
    background-color:#f3faff;
    color:${color};
  }

  :active {
    background-color:${bgColor};
    color:${color};
  }`
  }
`
)

export const ButtonPrimary = styled.button<any>(
  ({
    disabled,
    color = '#ffffff',
    bgColor = '#009ef7',
    bordertr = 5,
    bordertl = 5,
    borderbr = 5,
    borderbl = 5,
    grow = 0,
    fontSize = 16,
    paddingy = 10,
    paddingx = 20,
    fontWeight = 600,
    alignSelf = 'start',
  }) => `
  padding:${paddingy}px ${paddingx}px;
  border-top-right-radius:${bordertr}px;
  border-top-left-radius:${bordertl}px;
  border-bottom-right-radius:${borderbr}px;
  border-bottom-left-radius:${borderbl}px;
  border:none;
  background-color:${disabled ? '#e4e6ef' : bgColor};
  color:${color};
  font-size:${fontSize}px;
  font-weight:${fontWeight};
  flex-grow:${grow};
  justify-content:center;
  text-align:center;
  align-items:center;
  align-self: ${alignSelf};
  cursor:pointer;
  white-space: nowrap;
  
  ${
    !disabled &&
    `:hover {
    background-color:#80CFFB;
    color:${color};
  }

  :active {
    background-color:${bgColor};
    color:${color};
  }`
  }
`
)

export const ButtonTertiarry = styled.button<any>(
  ({
    disabled,
    color = '#ffffff',
    bgColor = '#009ef7',
    bordertr = 5,
    bordertl = 5,
    borderbr = 5,
    borderbl = 5,
    grow = 0,
    fontSize = 13,
    paddingy = 5,
    paddingx = 20,
    fontWeight = 600,
  }) => `
    border-top-right-radius:${bordertr}px;
    border-top-left-radius:${bordertl}px;
    border-bottom-right-radius:${borderbr}px;
    border-bottom-left-radius:${borderbl}px;
    color: #3f4254;
    background-color: #e4e6ef;
    padding: ${paddingy}px ${paddingx}px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size:${fontSize}px;
    font-weight: ${fontWeight};
    cursor: pointer;
    white-space: nowrap;
    border:none;
    display:flex;
    align-items:center;

    :hover {
      background-color: #eff2f5;
    }
  
    :active {
      background-color: #abadb8;
    }
`
)

export const ButtonDanger = styled.button<any>(
  ({
    disabled,
    color = '#f1416c',
    bgColor = '#feecf2',
    bordertr = 5,
    bordertl = 5,
    borderbr = 5,
    borderbl = 5,
    grow = 0,
    fontSize = 16,
    paddingy = 10,
    paddingx = 20,
    fontWeight = 600,
  }) => `
  padding:${paddingy}px ${paddingx}px;
  border-top-right-radius:${bordertr}px;
  border-top-left-radius:${bordertl}px;
  border-bottom-right-radius:${borderbr}px;
  border-bottom-left-radius:${borderbl}px;
  border:none;
  background-color:${disabled ? '#eaf6ff' : bgColor};
  color:${disabled ? '#c0e7fd' : color};
  font-size:${fontSize}px;
  font-weight:${fontWeight};
  cursor:pointer;
  white-space: nowrap;

  ${
    !disabled &&
    `:hover {
    background-color:#fffafc;
    color:${color};
  }

  :active {
    background-color:#ffdbe7;
    color:${color};
  }`
  }
`
)

export const CustomLoading = styled.div`
  width: 100%;
  height: 70vh;
  position: relative;
  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 500;
  }
`
