import {FC, useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {ButtonPrimary} from '../../components/CustomStyledComponents'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {accountApis} from '../../../setup/apis/account/accountApis'
import {triggerAlert} from '../../components/Alert'

type Props = {
  scrollTo: (str: string) => void
}

const regSchema = Yup.object().shape({
  email: Yup.string().trim().email('Wrong email format'),
})

const Public5: FC<Props> = ({scrollTo}) => {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const formik = useFormik({
    initialValues: {email: ''},
    validationSchema: regSchema,
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting}) => {},
  })

  async function submitEmail() {
    try {
      setLoading(true)
      const requestData = new FormData()
      requestData.append('email', formik.values.email)
      const response = await accountApis.ctaToConsider(requestData)
      if (response.status === 200) {
        triggerAlert('We will keep in touch with you', '', 5000)
        formik.values.email = ''
        setLoading(false)
      }
    } catch (e) {
      console.log('error', e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <div className='sky' />
      <div className='sky-2' />
      <div className='image'>
        <img src={toAbsoluteUrl('/media/svg/misc/little-stars.svg')} alt='' />
      </div>
      <div className='body-content'>
        <div className='quote'>
          <span>We will help you sell the world through an</span>
          <span>ecommerce arbitrage</span>
        </div>
        <div className='question'>
          <span>Got a question? Drop us a line and we will reach out to you</span>
        </div>
        <div className='email-address'>
          <div>
            <input
              type='email'
              className='input-value'
              placeholder='Type an email'
              {...formik.getFieldProps('email')}
            />
            {formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block '>
                  <span role='alert' className='text-danger'>
                    {formik.errors.email}
                  </span>
                </div>
              </div>
            )}
          </div>
          <ButtonPrimary
            className='ms-8'
            onClick={submitEmail}
            disabled={formik.errors.email || !formik.values.email || loading}
          >
            CTA to consider
          </ButtonPrimary>
        </div>
      </div>
      <div className='navigation-bar-5'>
        <span className='water-mark'>© 2022 Enver, All Rights Reserved</span>
      </div>
    </Container>
  )
}

export {Public5}

const Container = styled.div`
  width: 100%;
  min-height: 755px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #1d1d25;
  color: #ffffff;
  padding: 48px 120px;
  position: relative;
  overflow: hidden;

  .sky {
    position: absolute;
    width: 467px;
    height: 1233.79px;

    background: linear-gradient(180deg, rgba(84, 84, 212, 0.27) 0%, rgba(84, 84, 212, 0.1134) 100%);
    filter: blur(300px);
    border-radius: 233.5px;
    transform: rotate(-54.37deg);
    z-index: 2;
  }

  .sky-2 {
    position: absolute;
    width: 860px;
    height: 2576.87px;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(251, 168, 28, 0.048) 0%,
      rgba(224, 86, 136, 0.018) 100%
    );
    filter: blur(300px);
    border-radius: 233.5px;
    transform: rotate(65.71deg);
    z-index: 2;
  }

  .image {
    position: absolute;
    width: 100%;
    top: 150px;
    left: 400px;
  }

  .navigation-bar-5 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 96px;
  }

  .logo-5 {
    font-size: 28px;
    font-weight: 700;
  }

  .navigation-buttons-5 {
    font-size: 18px;
    font-weight: 400;
    color: #ffffffb2;
    min-width: 420px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }

  .navigation-button-5 {
    cursor: pointer;
  }

  .navigation-button-5:hover {
    font-weight: 500;
    color: #009ef7;
  }

  .information {
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 36px;
  }

  .body-content {
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .quote {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 64px;
    font-weight: 300;
    margin-bottom: 48px;
    white-space: nowrap;
  }

  .question {
    font-size: 24px;
    font-weight: 300;
    color: #e6e2e0;
    margin-bottom: 64px;
  }

  .water-mark {
    font-size: 16px;
    font-weight: 400;
    color: #ffffffb2;
  }

  .email-address {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    z-index: 2;
  }

  .input-value {
    display: block;
    width: 300px;
    border: 1px solid #cdcfda;
    border-radius: 8px;
    padding: 9px;
    font-size: 16px;
    font-weight: 500;
    background-color: #eeeaef;
    color: #3f4254;

    ::-ms-reveal {
      display: none;
    }
  }

  .input-value:focus {
    outline: none;
  }

  @media (max-height: 1100px) {
    min-height: 100vh;
  }
`
