import { FC } from 'react';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ButtonPrimary, ButtonTertiarry } from '../../components/CustomStyledComponents'
import { PlanOptionType } from '../account-creation/AccountCreation'

type Props = {
  data: PlanOptionType
  selected?: boolean
  goToReg: () => void
}

const PlanCard: FC<Props> = ({ selected, data, goToReg }) => {
  const history = useHistory()

  return (
    <Container selected={selected}>
      <div className='plan-header'>
        <span>{data.name || '5,000 LISTINGS'}</span>
      </div>
      <div className='plan-content'>
        <div className='plan-price'>
          <span>${Math.floor(data.priceInCents / 100) || '79'}</span>
          <span className='per-month'>/month</span>
        </div>
        <span className='access-time'>{'One month access'}</span>
      </div>
      {selected ? (
        <ButtonPrimary
          fontSize={16}
          paddingy={10}
          paddingx={80}
          fontWeight={600}
          alignSelf='center'
        >
          Select Plan
        </ButtonPrimary>
      ) : (
        <ButtonTertiarry
          fontSize={16}
          paddingy={10}
          paddingx={80}
          fontWeight={600}
          onClick={goToReg}
        >
          Select Plan
        </ButtonTertiarry>
      )}
    </Container>
  )
}

export { PlanCard }

const Container = styled.div<any>(
  ({ selected }) => `
max-width: 384px;
height: 380px;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: start;
${selected
      ? `
background-color: #1d1d25;
color: #ffffff;`
      : ''
    }
border: 1px solid #979797;
border-radius: 16px;
padding: 48px;
margin: 0 12px;
.plan-header {
  border-radius: 50px;
  padding: 6px 24px;
  font-size: 14px;
  font-weight: 500;
  background-color: ${selected ? '#e5e7e9' : '#3f4254'};
  color: ${selected ? '#1d1d25' : '#ffffff'};
}

.plan-content {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
}

.plan-price {
  font-size: 48px;
  font-weight: 400;
  color: ${selected ? '#e5e7e9' : '#333333'};
}

.per-month {
  font-size: 22px;
  font-weight: 400;
  color: #858585;
}

.access-time {
  margin-top: 6px;
  font-size: 16px;
  font-weight: 400;
  color: #858585;
}
`
)
