/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Option } from '../../../components/MultiColumnSelect'

type Props = {
  className: string
  data?: Option[]
  title: string
  removeRow: (item: Option, filterType: string) => void
  filterType: string
}

const ListsTable: FC<Props> = ({ className, data, title, removeRow }) => {
  function getTableRows() {
    return data?.map((item: Option, index: number) => (
      <tr key={index}>
        <td>
          <span className='text-dark fw-bold d-block mb-1 ps-5 fs-6'>{item.amount}</span>
        </td>
        {title === 'Brand' ? null : (
          <td>
            <span className='text-dark fw-bold d-block mb-1 fs-6'>{item.parentName}</span>
          </td>
        )}
        <td>
          <span className='text-dark fw-bold d-block mb-1 fs-6'>{item.title}</span>
        </td>
        <td>
          <span className='text-dark fw-bold d-block mb-1 fs-6'>
            {`${moment(new Date(item?.updateDate || new Date())).format('l')} ${moment(
              new Date(item?.updateDate || new Date())
            ).format('LT')}`}
          </span>
        </td>
        <td>
          <span
            className='btn fw-bolder btn-sm button-danger px-4 me-2'
            onClick={() => removeRow(item, title)}
          >
            Undo
          </span>
        </td>
      </tr>
    ))
  }
  return (
    <Container>
      <div className={`card ${className}`}>
        {/* begin::Body */}
        <div className=' py-3'>
          {/* begin::Table container */}
          <div className='table-responsive table-wrapper'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead className='sticky-top'>
                <tr className='fw-bolder fs-6 thead-color'>
                  <th className='ps-5 min-w-100px rounded-start ms-15'>Amount</th>
                  <th className={title === 'Brand' ? 'min-w-250px' : 'min-w-125px'}>{title}</th>
                  {title === 'Brand' ? null : <th className='min-w-125px'>Subcategory</th>}
                  <th className='min-w-125px'>Updated</th>
                  <th className='min-w-200px rounded-end'>Action</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>{getTableRows()}</tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </Container>
  )
}

export { ListsTable }

const Container = styled.div`
  .table-wrapper {
    height: 300px;
    overflow: auto;
    padding-right: 12px;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #40b6f9;
    }

    scrollbar-width: 6px;
    scrollbar-height: 6px;
    scrollbar-color: #40b6f9 #e4e6ef;
  }

  .sticky-top {
    z-index: 2;
  }

  .button-danger {
    color: #894848;
    background-color: #faeaea;
  }

  .thead-color {
    background-color: #d9f1fe;
    color: black;
  }
`
