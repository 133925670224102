import { FC, useEffect } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { countryApis } from '../../setup/apis/country/countryApis'
import { getCountries } from '../../app/modules/redux-toolkit/countrySlicer'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Alert } from '../components/Alert'
import { RootState } from '../../setup'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { PublicRoutes } from './PublicRoutes'
import { StripeRedirection } from '../layger/account-creation/StripeRedirection'

const Routes: FC = () => {
  const dispatch = useDispatch()
  const alert = useSelector((state: RootState) => state.alert)
  const isAuthorized = useSelector<RootState>(({ user }) => user.access_token, shallowEqual)

  useEffect(() => {
    _getCountries()
  }, [])

  async function _getCountries() {
    const response = await countryApis.getAll()
    dispatch(getCountries(response?.data))
  }

  return (
    <>
      <Switch>
        {!isAuthorized ? <PublicRoutes /> : <Redirect from='/auth' to='/' />}

        <Route exact path='/error' component={ErrorsPage} />
        <Route exact path='/stripe-checkout' component={StripeRedirection} />
        {!isAuthorized ? (
          <Redirect to='/auth/login' />
        ) : (
          <MasterLayout>
            <PrivateRoutes />
          </MasterLayout>
        )}
      </Switch>
      <Alert {...alert} />
    </>
  )
}

export { Routes }
