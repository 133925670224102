import { FC } from 'react';
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

type Props = {}

const Feature: FC<Props> = () => {
  const history = useHistory()

  return (
    <Container>
      <div className='feature-header'>
        <span>Icon</span>
        <span>Feature</span>
      </div>
      <div className='feature-content'>
        <span>Lorem ipsum dolor sit amet,</span>
        <span>consectetur adipiscing elit</span>
      </div>
    </Container>
  )
}

export { Feature }

const Container = styled.div`
  width: 380px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #1d1d25;
  color: #ffffff;
  border: 1px solid #979797;
  border-radius: 16px;
  padding: 48px;
  margin:0 12px;

  .feature-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
    font-weight: 600;
    marign-bottom: 48px;
  }

  .feature-content {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    font-weight: 400;
    white-space: nowrap;
    color: #cccccc;
  }
`
