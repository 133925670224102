import React, { useState, useEffect } from 'react'
import PricingCell from './PricingCell'
import styled from 'styled-components'
import Input from '../../../components/Input'
import Select from '../../../components/Select'
import { ButtonPrimary, CustomHeader } from '../../../components/CustomStyledComponents'
import { FaPlus, FaTrash } from 'react-icons/fa'
import { SelectContainer } from './StoreSettings'
import { storeSettingsApis } from '../../../../setup/apis/store/storeSettingsApis'
import {
  PricingMarkupItemType,
  PricingMarkupType,
} from '../../../../setup/types/request-data-types/RequestDataTypes'
import { CurrencyType } from '../../../../setup/types/response-data-types/ResponseDataTypes'
import { triggerAlert } from '../../../components/Alert'

type Props = {
  storeId: number
  className: string
  data?: Array<object>
  currencyOptions: CurrencyType[]
  pricingPointOptions: CurrencyType[]
}

export type MarkupRow = {
  startingPoint: {
    cash: number | ''
  }
  defaultVal: {
    cash: number | ''
    percent: number | ''
  }
  minVal: {
    cash: number | ''
    percent: number | ''
    relative: boolean
  }
  maxVal: {
    cash: number | ''
    percent: number | ''
    relative: boolean
  }
}

const blankMarkup: MarkupRow = {
  startingPoint: { cash: '' },
  defaultVal: { cash: '', percent: '' },
  minVal: { cash: '', percent: '', relative: false },
  maxVal: { cash: '', percent: '', relative: false },
}

const PricingSettings: React.FC<Props> = ({
  className,
  storeId,
  currencyOptions,
  pricingPointOptions,
}) => {
  const [markupRows, setMarkupRows] = useState<MarkupRow[]>([blankMarkup])
  const [inputCurrency, setInputCurrency] = useState<CurrencyType>(currencyOptions[0])
  const [tableCurrency, setTableCurrency] = useState<CurrencyType>(currencyOptions[0])
  const [inputPricingPoint, setInputPricingPoint] = useState<CurrencyType>(pricingPointOptions[0])
  const [loading, setLoading] = useState<boolean>(false)
  const [invalidRows, setInvalidRows] = useState<number[]>([])

  useEffect(() => {
    getData()
  }, [storeId])

  useEffect(() => {
    findInvalidRows()
  }, [markupRows])

  async function getData() {
    const response: PricingMarkupType = await storeSettingsApis.getPricingSettings(storeId)

    const mappedResponse: MarkupRow[] = response?.rows?.map(
      (item: PricingMarkupItemType, i: number) => ({
        startingPoint: {
          cash: item?.startingPoint,
        },
        defaultVal: {
          cash: item?.defaultMarkupFlat1,
          percent: item?.defaultMarkupRate1InPercentage,
        },
        minVal: {
          cash: item?.minMarkupFlat1,
          percent: item?.minMarkupRate1InPercentage,
          relative: item?.minRelative,
        },
        maxVal: {
          cash: item?.maxMarkupFlat1,
          percent: item?.maxMarkupRate1InPercentage,
          relative: item?.maxRelative,
        },
      })
    )
    setMarkupRows((prev) => (response?.rows?.length ? mappedResponse : [{ ...blankMarkup, startingPoint: { cash: 0 } }]))
    setInputCurrency(
      response?.pricingTable?.inputCurrencyCode
        ? {
          value: response?.pricingTable?.inputCurrencyCode,
          label: response?.pricingTable?.inputCurrencyCode,
        }
        : currencyOptions[0]
    )
    setTableCurrency(
      response?.pricingTable?.tableCurrencyCode
        ? {
          value: response?.pricingTable?.tableCurrencyCode,
          label: response?.pricingTable?.tableCurrencyCode,
        }
        : currencyOptions[0]
    )
    setInputPricingPoint(
      response?.pricingTable?.inputPricingPoint
        ? {
          value: response?.pricingTable?.inputPricingPoint,
          label: response?.pricingTable?.inputPricingPoint,
        }
        : pricingPointOptions[0]
    )
  }

  function _onChange({ target }: any, which: keyof MarkupRow, index: number) {
    setMarkupRows((prev) =>
      prev.map((item: MarkupRow, i: number) => _renderItem({ target }, which, index, i, item))
    )
  }

  function findInvalidRows() {
    let rows: any = {}
    markupRows.forEach((item, i) => {
      let idx = item.startingPoint.cash
      rows[idx] = {
        count: (rows?.[idx]?.count || 0) + 1,
        indexes: [...(rows?.[idx]?.indexes || []), i],
      }
    })
    setInvalidRows((prev) =>
      [].concat(
        ...Object.keys(rows)?.reduce((acc: any[], key) => {
          if (rows[key].count > 1) {
            return [...acc, ...(rows[key].indexes || [])]
          }
          return acc
        }, [])
      )
    )
  }

  function _renderItem(
    { target }: any,
    which: keyof MarkupRow,
    index: number,
    i: number,
    item: MarkupRow
  ) {
    if (i === index) {
      if (target.type === 'checkbox') {
        return { ...item, [which]: { ...item[which], [target.name]: target.checked } }
      }
      return {
        ...item,
        [which]: { ...item[which], [target.name]: target.value },
      }
    }
    return item
  }

  function onRowAdded(index: number) {
    setMarkupRows((prev) => [...prev.slice(0, index + 1), blankMarkup, ...prev.slice(index + 1)])
  }

  function onRowDeleted(index: number) {
    setMarkupRows((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
  }

  function getTableRows() {
    return markupRows.map((item, index) => (
      <tr key={index}>
        <td>
          <div className='d-flex align-items-center'>
            <Strong>FROM</Strong>
            <TdContent className='d-flex justify-content-start'>
              <Input
                id={`cash${index}`}
                type='number'
                name='cash'
                value={item?.startingPoint?.cash}
                placeholder='0.00'
                readOnly={index === 0}
                onChange={(e) => {
                  _onChange(e, 'startingPoint', index)
                }}
                className={`form-control ${invalidRows.includes(index) ? `invalid` : ``}`}
                min={0}
              />
            </TdContent>
            {/* <Strong>TO</Strong>
            <TdContent className='d-flex justify-content-start input-group'>
              <Input
                id={`cash${index}`}
                type='number'
                name='cash'
                value={item?.toVal?.cash)}
                placeholder='0.00'
                onChange={(e) => {
                  _onChange(e, 'toVal', index)
                }}
                className={`form-control ${item?.toVal?.invalid ? `invalid` : ``}`}
                min={0}
              />
            </TdContent> */}
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'></div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <PricingCell
              currencyOptions={currencyOptions}
              onChange={_onChange}
              markup={item?.defaultVal}
              value='defaultVal'
              index={index}
              selectClassName='custom-select'
              inputClassName='custom-input'
            />
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'></div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <PricingCell
              currencyOptions={currencyOptions}
              onChange={_onChange}
              markup={item.minVal}
              value='minVal'
              index={index}
              selectClassName='custom-select'
              inputClassName='custom-input'
            />
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'>
            <Input
              id={`relative${index}`}
              name='relative'
              className='form-check-input '
              type='checkbox'
              value='1'
              checked={item.minVal.relative}
              onChange={(e) => _onChange(e, 'minVal', index)}
            />
            <label className='to-left'>Relative to default markup</label>
          </div>
        </td>
        <td>
          <div className='d-flex align-items-center'>
            <PricingCell
              currencyOptions={currencyOptions}
              onChange={_onChange}
              markup={item.maxVal}
              value='maxVal'
              index={index}
              selectClassName='custom-select'
              inputClassName='custom-input'
            />
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'>
            <Input
              id={`relative${index}`}
              name='relative'
              className='form-check-input widget-9-check'
              type='checkbox'
              value='1'
              checked={item.maxVal.relative}
              onChange={(e) => _onChange(e, 'maxVal', index)}
            />
            <label className='to-left'>Relative to default markup</label>
          </div>
        </td>
        <td className='pe-1'>
          <div className='d-flex align-items-center'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
              onClick={(e) => onRowAdded(index)}
            >
              <FaPlus color='#a1a5b7' />
            </button>
            <button
              className={`btn btn-icon  btn-bg-light btn-active-color-danger btn-sm ${index === 0 && `invisible`
                }`}
              onClick={() => onRowDeleted(index)}
              style={{ marginLeft: '20px' }}
            >
              <FaTrash color='#a1a5b7' />
            </button>
          </div>
          <div className='form-check form-check-sm form-check-custom form-check-solid relative'></div>
        </td>
      </tr>
    ))
  }

  async function applyChanges() {
    try {
      setLoading(true)
      const requestDataItems: PricingMarkupItemType[] = markupRows.map((item) => ({
        startingPoint: +item?.startingPoint?.cash || 0,
        defaultMarkupFlat1: +item?.defaultVal?.cash || 0,
        defaultMarkupRate1InPercentage: +item?.defaultVal?.percent || 0,
        minMarkupFlat1: +item?.minVal?.cash || 0,
        minMarkupRate1InPercentage: +item?.minVal?.percent || 0,
        minRelative: item?.minVal?.relative || false,
        maxMarkupFlat1: +item?.maxVal?.cash || 0,
        maxMarkupRate1InPercentage: +item?.maxVal?.percent || 0,
        maxRelative: item?.maxVal?.relative || false,
      }))
      const requestData = {
        rows: requestDataItems,
        pricingTable: {
          inputCurrencyCode: inputCurrency?.value,
          tableCurrencyCode: tableCurrency?.value,
          inputPricingPoint: inputPricingPoint?.value,
        },
      }
      const response = await storeSettingsApis.updatePricingSettings(storeId, requestData)
      if (response?.status === 200) {
        triggerAlert('Changes saved successfully')
      }
    } catch (e) {
      console.log('error', e)
      triggerAlert('Something went wrong', 'error')
    } finally {
      setLoading(false)
    }
  }

  function isInValid() {
    const initialRow = markupRows[0]
    const invalid = markupRows.findIndex(item => item.startingPoint.cash === '') !== -1 || Object.values(initialRow).
      every(value => (value.cash === 0 || value.cash === ''))
    if (!markupRows.length || loading || invalidRows.length || invalid) return true
  }

  return (
    <Container className={`card ${className}`}>
      {/* begin::Header */}
      <CustomHeader>Pricing Markup Settings</CustomHeader>
      {/* end::Header */}
      {/* begin::Body */}
      <SelectContainer className='currency-select'>
        <label className='form-label fw-bold'>Select Input Currency</label>
        <Select
          name='layout-builder[layout][header][width]'
          value={inputCurrency}
          onChange={(option) => setInputCurrency(option)}
          options={currencyOptions}
          withRs
          placeholder='Select currency...'
        />
      </SelectContainer>
      <SelectContainer className='currency-select'>
        <label className='form-label fw-bold'>Select Table Currency</label>
        <Select
          name='layout-builder[layout][header][width]'
          value={tableCurrency}
          onChange={(option) => setTableCurrency(option)}
          options={currencyOptions}
          withRs
          placeholder='Select currency...'
        />
      </SelectContainer>
      <SelectContainer className='currency-select'>
        <label className='form-label fw-bold'>Select Input Pricing Point</label>
        <Select
          name='layout-builder[layout][header][width]'
          value={inputPricingPoint}
          onChange={(option) => setInputPricingPoint(option)}
          options={pricingPointOptions}
          withRs
          placeholder='Select currency...'
        />
      </SelectContainer>
      <div className='card-body py-3'>
        <div className='table-responsive table-wrapper'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead className=' bg-white'>
              <tr className='fw-bolder text-muted'>
                <th className='w-175px'>Purchase Price</th>
                <th className='min-w-100px'>Default Markup</th>
                <th className='min-w-120px'>Min Markup</th>
                <th className='min-w-100px'>Max Markup</th>
                <th className='min-w-50px'>Actions</th>
              </tr>
            </thead>
            <tbody>{getTableRows()}</tbody>
          </table>
        </div>
      </div>
      <hr className='custom-hr' />
      <div className='apply-button'>
        <ButtonPrimary onClick={applyChanges} disabled={isInValid()}>
          Apply Changes
        </ButtonPrimary>
      </div>
    </Container>
  )
}

export { PricingSettings }

const Container = styled.div`
  .custom-hr {
    margin: 0;
  }

  .currency-select {
    margin: 0 0 24px 24px;
  }

  .relative {
    margin-top: 10px;
  }

  .to-left {
    margin-left: 5px;
    font-size: 13px;
    font-weight: 500;
    color: #a1a5bd;
  }

  .table-wrapper {
    // height: 43vh;
    // overflow: auto;
  }

  thead {
    z-index: 1;
  }

  .custom-select {
    background-color: #d9f1fe;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: none;
    :focus {
      outline: none;
    }
  }

  .custom-input {
    background-color: #d9f1fe;
    border: none;
  }

  .apply-button {
    margin: 24px;
  }

  .info-text {
    border-radius: 8px;
    background-color: #eff2f5;
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 12px;
    padding: 12px;
  }
`

const TdContent = styled.div`
  .invalid {
    color: tomato;
  }

  input {
    font-size: 14px;
    padding: 3px;
  }

  select {
    :focus {
      outline: none;
    }
    border: none;
  }
`

const Strong = styled.strong`
  margin-inline: 3px;
  font-size: 12px;
`
