import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { ButtonPrimary } from '../../components/CustomStyledComponents';

type Props = {
  scrollTo: (str: string) => void
}

const Public1: FC<Props> = ({ scrollTo }) => {
  const history = useHistory()

  return (
    <Container>
      <div className='sky' />

      <div className='body-content'>
        <div className='body-left'>
          <div className='title'>
            <span>Market</span>
            <span>Gap</span>
          </div>
          <div className='information'>
            <span>Software solution that gaps online</span>
            <span>marketplaces seamlessly globally</span>
          </div>
          <ButtonPrimary fontSize={18} onClick={() => history.push('/registration')}>
            Try Now{' '}
            <img className='ms-3' src={toAbsoluteUrl('/media/svg/misc/arrow-upright.svg')} />
          </ButtonPrimary>
        </div>
        <div className='body-right'>
          <img src={toAbsoluteUrl('/media/svg/misc/hero-illustration.svg')} alt='' width={540} />
        </div>
      </div>
    </Container>
  )
}

export { Public1 }

const Container = styled.div`
  width: 100%;
  min-height: 755px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: #1d1d25;
  color: #ffffff;
  padding: 48px 120px;
  position: relative;
  overflow: hidden;

  .sky {
    position: absolute;
    width: 599.58px;
    height: 652.85px;
    left: 800px;
    top: 200px;

    background: linear-gradient(180deg, rgba(84, 84, 212, 0.2) 0%, rgba(84, 84, 212, 0.084) 100%);
    filter: blur(300px);
    border-radius: 233.5px;
    transform: matrix(-0.58, -0.81, -0.81, 0.58, 0, 0);
    z-index:2;
  }

  .title {
    display: flex;
    flex-direction: column;
    font-size: 88px;
    font-weight: 400;
    margin-bottom: 36px;
    span:first-child {
      margin-bottom: -40px;
    }
  }

  .information {
    display: flex;
    flex-direction: column;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 36px;
  }

  .body-content {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
  }

  .body-left {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .body-right {
  }

  @media (max-height: 1100px) {
    min-height: 100vh;
  }
`
