import moment from 'moment'
import { FC } from 'react';
import styled from 'styled-components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { CustomHeader } from '../../components/CustomStyledComponents'
import { OrderStatus } from './OrderList'
import type { OrderType } from './OrderList'

type Props = {
  data: OrderType
}

const OrderInfo: FC<Props> = ({ data }) => {
  const statusIcon = OrderStatus[data.orderStatus]?.toLowerCase()
  return (
    <Container>
      <div className='order-header'>
        <CustomHeader className='order-id'>{`Order #${data.storeMicroOrderId}`}</CustomHeader>
        <div className={`icon-${statusIcon}`}>
          {OrderStatus[data?.orderStatus]}{' '}
          {<img src={toAbsoluteUrl(`/media/svg/misc/${statusIcon}.svg`)} className='ms-1' alt={data.orderStatus} />}
        </div>
      </div>
      <div className='order-container'>
        <div className='data-container'>
          <div className='data-label'>
            <span>Ordered On</span>
          </div>
          <div className='data-value'>
            <span>{`${moment(data?.orderedOn).format('LLL')}`}</span>
          </div>
        </div>
        <hr className='m-0 custom-hr' />
        <div className='data-container'>
          <div className='data-label'>
            <span>Updated On</span>
          </div>
          <div className='data-value'>
            <span>{`${moment(data?.updatedOn).format('LLL')}`}</span>
          </div>
        </div>
        <hr className='m-0 custom-hr' />
        <div className='data-container'>
          <div className='data-label'>
            <span>Latest Ship Date</span>
          </div>
          <div className='data-value'>
            <span>{`${moment(data?.updatedOn).format('LLL')}`}</span>
          </div>
        </div>
        <hr className='m-0 custom-hr' />
        <div className='data-container'>
          <div className='data-label data-label-total-price'>
            <span>Total Price</span>
          </div>
          <div className='data-value data-value-total-price'>
            <div className=''>
              <div className=''>
                <span>{`$${data.orderTotal}`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export { OrderInfo }

const Container = styled.div`
  border: 1px solid #cdcfda;
  box-shadow: 0 0 5px 1px #7892a50d;
  border-radius: 15px;
  margin: 24px 0 24px 24px;
  width: 600px;
  color: #3f4254;

  .order-header {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
  }

  .order-id {
    color: #3f4254;
  }

  .order-container {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px 24px;
  }

  .data-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .data-label,
  .data-value {
    padding: 12px 0;
  }

  .data-label {
    width: 170px;
    font-size: 16px;
    font-weight: 500;
  }

  .data-value {
    font-size: 16px;
    text-align: end;
  }

  .data-value-total-price {
    font-weight: 700;
    font-size: 24px;
  }

  .icon-shipped,
  .icon-unshipped,
  .icon-pending {
    margin-right: 24px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 4px 16px;
    font-size: 14px;
    font-weight: 600;
  }

  .icon-shipped {
    background-color: #d9ffeb;
    color: #50cd89;
    border: 2px solid #50cd89;
  }

  .icon-unshipped {
    background-color: #fff5f8;
    color: #f1416c;
    border: 2px solid #f1416c;
  }

  .icon-pending {
    background-color: #fff8dd;
    color: #ffc700;
    border: 2px solid #ffc700;
  }

  .custom-hr {
    border-top: 1px dashed #d9dbe3;
  }
`
