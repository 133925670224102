import {FC, useRef, useState} from 'react'
import styled from 'styled-components'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  ButtonPrimary,
  ButtonSecondary,
  CustomHeader,
  FileUploadButton,
} from '../../components/CustomStyledComponents'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {FaChevronLeft} from 'react-icons/fa'
import {useHistory} from 'react-router'
import {accountApis} from '../../../setup/apis/account/accountApis'
import {
  capitalize,
  nameValidation,
  only2Words,
  preventNumbers,
  preventSpaces,
} from '../../utils/inputFieldUtils'
import {triggerAlert} from '../../components/Alert'
import {getFileSizeInKB} from '../../utils/helperUtils'
import clsx from 'clsx'
import {RiCloseLine} from 'react-icons/ri'

type Props = {
  fromInner: boolean
}

type Topic = {
  id: number
  title: string
}

const dataSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('Your name is required')
    .min(2, 'Minimum 2 symbols')
    .max(50, 'Maximum 50 symbols'),
  email: Yup.string().trim().email('Wrong email format').required('Email is required'),
  // message: Yup.string().trim().trim().required('Message is required'),
})

const initialValues = {
  name: '',
  email: '',
  // message: '',
}

const ContactUs: FC<Props> = ({fromInner}) => {
  const [selectedTopic, setSelectedTopic] = useState<number>(6)
  const history = useHistory<any>()
  const [loading, setLoading] = useState(false)
  const [attachments, setAttachments] = useState<File[]>([])
  const [messageTouched, setMessageTouched] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const fileRef = useRef<any>(null)

  const formik = useFormik({
    initialValues,
    validationSchema: dataSchema,
    onSubmit: (values, {setSubmitting}) => {
      console.log('submitted')
    },
  })

  function _selectTopic(topic: Topic) {
    setSelectedTopic(+topic.id)
  }

  function getTopics(data: Topic[]) {
    return data.map((item: Topic, i) => (
      <div
        key={i}
        onClick={() => _selectTopic(item)}
        className={`topic ${+item.id === selectedTopic ? 'topic-selected' : ''}`}
      >
        <span>{item.title}</span>
      </div>
    ))
  }

  async function sendMessage() {
    try {
      setLoading(true)
      const requestData = new FormData()
      requestData.append('userName', capitalize(formik?.values?.name))
      requestData.append('email', formik?.values?.email)
      requestData.append(
        'topic',
        topics.find((item) => item.id === selectedTopic)?.title || topics[6].title
      )
      requestData.append('userMessage', message)
      attachments.forEach((file) => {
        requestData.append('attachments', file)
      })
      const response = await accountApis.sendMessage(requestData)
      if (response.status === 200) {
        triggerAlert('Message sent successfully')
        formik.values.name = ''
        formik.values.email = ''
        setAttachments([])
        setMessage('')
        setMessageTouched(false)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  function isValidForm() {
    const values = Object.values(formik.values)
    const errors = Object.values(formik.errors)
    return (
      values.every((item) => item?.toString().length) &&
      errors.length === 0 &&
      message?.trim()?.length
    )
  }

  function getThumbnails() {
    return attachments?.map((item: File) => (
      <span className='attachment'>
        {item?.name}
        <RiCloseLine fontSize={20} className='close-icon' onClick={() => removeFile(item)} />
      </span>
    ))
  }

  function uploadFiles(e: any) {
    const sizeLimitOverFlowed = [...e.target.files].some(
      (item) => getFileSizeInKB(item?.size || 0) > 1024
    )
    if (sizeLimitOverFlowed) {
      triggerAlert('Some of the files are more than 1 MB...', 'error', 10000)
      return
    }
    const filteredFiles = [...e.target?.files]?.filter(
      (item: File) => attachments.findIndex((file: File) => file?.name === item?.name) === -1
    )
    setAttachments((prev) => [...prev, ...filteredFiles])
  }

  function handleMessage(e: any) {
    let value = ''
    if (e.target.value) {
      value = e.target.value?.[0]?.toUpperCase() + e.target.value?.slice(1)
    }
    setMessage(value)
  }

  function removeFile(file: File) {
    setAttachments((prev) =>
      prev.filter((item: File) => item?.name?.toLowerCase() !== file?.name?.toLowerCase())
    )
  }

  return (
    <Container className='card'>
      <div className='d-flex w-100 justify-content-between align-items-center'>
        <CustomHeader>Contact Us</CustomHeader>
        {history?.location?.state?.from ? (
          <ButtonSecondary className='me-8' onClick={() => history.goBack()}>
            Go Back
          </ButtonSecondary>
        ) : null}
        {fromInner ? null : (
          <ButtonSecondary className='back' onClick={() => history.push('/public')}>
            <FaChevronLeft /> Back To Home
          </ButtonSecondary>
        )}
      </div>
      <hr className='m-0' />
      <div className='contactus-body'>
        <div className='body-upper'>
          <div className='upper-left'>
            <div className='quote'>
              <span>Give Us A Quote</span>
              <span>And We Will Reach Out</span>
              <span>To You As Soon</span>
              <span>As Possible</span>
            </div>
            <div className='inputs'>
              <div className='input-wrapper'>
                <span className='input-label'>Your Name</span>
                <input
                  type='text'
                  onKeyDown={(e) => {
                    only2Words(e)
                    preventNumbers(e)
                    nameValidation(e)
                    preventSpaces(e)
                  }}
                  onPaste={(e) => {
                    preventSpaces(e)
                    nameValidation(e)
                  }}
                  className={clsx(
                    'input-value input-cap',
                    {'input-invalid': formik.touched.name && formik.errors.name},
                    {
                      'input-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  placeholder='Insert your name'
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='input-wrapper'>
                <span className='input-label'>Your Email</span>
                <input
                  type='text'
                  className={clsx(
                    'input-value ',
                    {'input-invalid': formik.touched.email && formik.errors.email},
                    {
                      'input-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  placeholder='Insert your email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='image'>
            <img
              src={toAbsoluteUrl('/media/downloaded-images/contactus-image.png')}
              alt='image'
              width='350px'
            />
          </div>
        </div>
        <div className='body-lower'>
          <div className='topics-wrapper'>
            <div className='topics-header'>
              <span>Topic</span>
            </div>
            <div className='topics'>{getTopics(topics)}</div>
          </div>
          <div className='message-wrapper'>
            <div className='topics-header'>
              <span>Message Field</span>
            </div>
            <textarea
              placeholder='Leave a message here'
              onClick={() => setMessageTouched(true)}
              className={clsx('input-value message ', {
                'input-invalid': messageTouched && !message,
              })}
              value={message}
              onChange={(e) => handleMessage(e)}
            />
            {messageTouched && !message?.trim() && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{'Message is required'}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {attachments?.length ? (
          <div className='topics-wrapper'>
            <div className='topics-header'>
              <span>Attachments</span>
            </div>
            <div className='pt-5 d-flex flex-column align-items-start'>{getThumbnails()}</div>
          </div>
        ) : null}
      </div>
      <hr className='m-0' />
      <div className='buttons'>
        <FileUploadButton className='me-5'>
          Attach file{' '}
          <input
            ref={fileRef}
            onClick={() => {
              fileRef.current.value = null
            }}
            type='file'
            accept='.csv, .doc, .docx, .jpeg, .jpg, .png, .pdf'
            onChange={(e) => uploadFiles(e)}
            multiple
          />
        </FileUploadButton>
        <ButtonPrimary onClick={sendMessage} disabled={!isValidForm() || loading}>
          Send Message
          {loading ? (
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          ) : null}
        </ButtonPrimary>
      </div>
    </Container>
  )
}

export {ContactUs}

const Container = styled.div`
  margin: auto;
  margin-block: 40px;
  width: 70%;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);

  min-width: 1200px;
  overflow-x: auto;

  input[type='file'] {
    display: none;
  }

  .contactus-body {
    padding: 24px;
  }

  .body-upper {
    display: flex;
  }

  .upper-left {
  }

  .quote {
    margin-top: 48px;
    font-size: 40px;
    font-weight: 600;
    width: 666px;
    display: flex;
    flex-direction: column;
  }

  .image {
    margin: 24px 48px 0 12px;
  }

  .inputs {
    margin-top: 48px;
    height: 160px;
  }

  .input-wrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
  }

  .input-label {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
  }

  .input-value {
    border: 2px solid #e3e4ea;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
  }

  .input-value:focus {
    outline: none;
    border: 1px solid #2684ff;
    box-shadow: 0 0 1px 2px #c0e7fd;
  }

  .input-invalid {
    border: 1px solid tomato;
  }

  .topics-wrapper {
    padding: 48px 0;
  }

  .topics {
    display: flex;
  }

  .topics-header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .topic {
    background-color: #e4e6ef;
    border: 2px solid #e4e6ef;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin-right: 12px;
    cursor: pointer;
  }

  .topic:hover {
    background-color: #e9ebec;
  }

  .topic-selected {
    border: 2px solid #019ef7;
    background-color: #e6f5fe;
    color: #07a1f7;
  }

  .topic-selected:hover {
    background-color: #e6f5fe;
  }

  .message {
    width: 666px;
    min-height: 100px;
  }

  .buttons {
    margin: 24px;
    display: flex;
  }

  .primary-font {
    font-size: 16px;
  }

  .back {
    margin-right: 24px;
  }

  .input-cap {
    text-transform: capitalize;

    //placeholder
    ::-webkit-input-placeholder {
      text-transform: none;
    }
    :-moz-placeholder {
      text-transform: none;
    }
    ::-moz-placeholder {
      text-transform: none;
    }
    :-ms-input-placeholder {
      text-transform: none;
    }
    ::placeholder {
      text-transform: none;
    }
  }

  .close-icon {
    margin-left: 12px;
    cursor: pointer;
  }

  .attachment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 250px;
    border-radius: 4px;
    background-color: #c0e7fd;
    border: 1px solid #cdcfda;
    margin-right: 24px;
    padding: 12px 12px 12px 24px;
    font-size: 14px;
    font-weight: 500;
    margin: 12px 0;
  }

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const topics = [
  {
    id: 1,
    title: 'Payment issues',
  },
  {
    id: 2,
    title: 'Store settings',
  },
  {
    id: 3,
    title: 'Order problems',
  },
  {
    id: 4,
    title: 'Product issues',
  },
  {
    id: 5,
    title: 'Subscription plan issues',
  },
  {
    id: 6,
    title: 'Other',
  },
]
