import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from '../layger/registration/Registration'
import {ContactUs} from '../layger/contact-us/ContactUs'
import {AuthPage} from '../modules/auth'
import {PublicPage} from '../layger/public-page/PublicPage'

export function PublicRoutes() {
  return (
    <Switch>
      <Route path='/auth' component={AuthPage} />
      <Route path='/registration' component={Registration} />
      <Route path='/contact-us' component={ContactUs} />
      <Route exact path='/public' component={PublicPage} />
      <Redirect to='/public' />
    </Switch>
  )
}
