import { FC, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { KTSVG } from '../../../_metronic/helpers'
import { ButtonTertiarry } from '../../components/CustomStyledComponents'
import { Public1 } from './Public1'
import { Public2 } from './Public2'
import { Public3 } from './Public3'
import { Public4 } from './Public4'
import { Public5 } from './Public5'

type Props = {}

const PublicPage: FC<Props> = () => {
  const history = useHistory()
  const homeRef = useRef<any>(null)
  const aboutUsRef = useRef<any>(null)
  const pricingRef = useRef<any>(null)
  const [scrollY, setScrollY] = useState<boolean>(false)

  history.listen(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  function handleScroll() {
    setScrollY(window.scrollY > 600)
  }

  function scrollTo(where: string) {
    switch (where) {
      case 'home':
        homeRef?.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'about-us':
        aboutUsRef?.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'pricing':
        pricingRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        break
    }
  }

  return (
    <Container>
      <div className='navigation-bar'>
        <div className='logo'>
          <span>Logo</span>
        </div>
        <div className='navigation-buttons'>
          <span className='navigation-button' onClick={() => scrollTo('home')}>
            Home
          </span>
          <span className='navigation-button' onClick={() => scrollTo('about-us')}>
            About us
          </span>
          <span className='navigation-button' onClick={() => scrollTo('pricing')}>
            Pricing
          </span>
          <span className='navigation-button' onClick={() => history.push('/contact-us')}>
            Contact us
          </span>
        </div>
        <ButtonTertiarry
          fontSize={16}
          paddingx={20}
          fontWeight={600}
          onClick={() => history.push('/auth/login')}
        >
          Log in
        </ButtonTertiarry>
      </div>
      {scrollY ? (
        <div className='arrow-up scrolltop' onClick={() => scrollTo('home')}>
          <KTSVG path='/media/icons/duotone/Navigation/Up-2.svg' />
        </div>
      ) : null}
      <div ref={homeRef}>
        <Public1 scrollTo={scrollTo} />
      </div>
      <div ref={aboutUsRef}>
        <Public2 />
        <Public3 />
      </div>
      <div ref={pricingRef}>
        <Public4 />
      </div>
      <Public5 scrollTo={scrollTo} />
    </Container>
  )
}

export { PublicPage }

const Container = styled.div`
  margin: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  min-width: 1400px;
  max-width: 2000px;
  overflow: hidden;
  position: relative;

  .navigation-bar {
    position: fixed;
    min-width: 1400px;
    max-width: 2000px;
    background-color: #1d1d25;
    width: 100%;
    height: 80px;
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    padding: 0 120px;
  }

  .logo {
    font-size: 28px;
    font-weight: 700;
  }

  .navigation-buttons {
    font-size: 18px;
    font-weight: 400;
    color: #ffffffb2;
    min-width: 420px;
    display: flex;
    justify-content: space-between;
    text-decoration: none;
  }

  .navigation-button {
    // margin: 0 18px;
    cursor: pointer;
    z-index:99;
  }

  .navigation-button:hover {
    font-weight: 500;
    color: #009ef7;
  }

  .arrow-up {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 3;
    cursor: pointer;
    background-color: #009EF7;
    opacity: 0.5;
    animation: animation-scrolltop .4s ease-out .7;
    width:40px;
    height:40px;
    border-radius: 8px;
    display:flex;
    justify-content: center;
    align-items:center;
    color: #ffffff;
  }

  .arrow-up:hover {
    opacity: 1;
  }

  @keyframes animation-scrolltop {
    from   { margin-bottom: -15px }
    to {     margin-bottom: 0; }
  }
`
