import axios from 'axios'
import {
  SetRangeFilterType,
  AddValueFilterType,
  ReviewFilterType,
} from '../../types/request-data-types/RequestDataTypes'

export const importProductsApis = {
  async checkExistingFilter(storeId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/ImportSetting/check/${storeId}`)
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
  async addFilter(storeId: number, name: string) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/ImportSetting/add?storeId=${storeId}&name=${name}`)
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
  async getFilter(storeId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/ImportSetting/getFilter?storeId=${storeId}`)
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
  async setRangeFilter(requestData: SetRangeFilterType) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/ImportSetting/setProperty`, requestData)
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
  async addStringFilter(requestData: AddValueFilterType[]) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/ImportSettingFilter/Add`, requestData)
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
  async removeStringFilter(requestData: AddValueFilterType[]) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/ImportSettingFilter/Remove`, requestData)
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
  async getReviewProducts(requestData: any) {
    Object.keys(requestData).forEach((key) => {
      if (!requestData[key]) {
        requestData[key] = null
      }
    })

    return await axios
      .post(`${process.env.REACT_APP_PROXY}/ImportReview`, {
        ...requestData,
        page: requestData?.page + 1,
      })
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
  async addProduct(storeId: number, asin: string) {
    return await axios
      .post(
        `${process.env.REACT_APP_PROXY}/ImportReview/addProduct?storeId=${storeId}&productId=${asin}`
      )
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
  async removeProduct(storeId: number, asin: string) {
    return await axios
      .post(
        `${process.env.REACT_APP_PROXY}/ImportReview/removeProduct?storeId=${storeId}&productId=${asin}`
      )
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
  async confirmImport(storeId: number) {
    return await axios
      .post(`${process.env.REACT_APP_PROXY}/ImportReview/save?storeId=${storeId}`)
      .then((res) => res)
      .catch((er) => { throw er })
  },
  async getTotalCounts(storeId: number) {
    return await axios
      .get(`${process.env.REACT_APP_PROXY}/ImportReview/count?storeId=${storeId}`)
      .then((res) => res?.data)
      .catch((er) => console.log('error', er))
  },
}
