import { FC, useEffect, useRef } from 'react';
import { useState } from 'react'
import { Pagination2 } from '../../components/Pagination2'
import Select from '../../components/Select'
import { SelectContainer } from '../stores/store-settings/StoreSettings'
import { DateFilters, Product, ProductTable } from './ProductTable'
import { FilterData } from '../filters/FilterTypes'
import styled from 'styled-components'
import { productApis } from '../../../setup/apis/product/productApis'
import { PagingType } from '../../../setup/types/request-data-types/RequestDataTypes'
import { useSelector } from 'react-redux'
import { activeStoreSelector } from '../../modules/selectors/reduxSelectors'
import { StoreType } from '../../../setup/types/response-data-types/ResponseDataTypes'
import { useHistory } from 'react-router-dom'

type Props = {}

export enum ProductStatusEnum {
  Imported,
  Pending,
  Rejected,
}

export enum PriceUpdateEnum {
  Same,
  Changed,
  Removed,
}

export enum InvUpdateEnum {
  Same,
  Changed,
  Removed,
}

const statusFilterData = [
  {
    title: 'Finished',
    selected: false,
  },
  {
    title: 'Process',
    selected: false,
  },
  {
    title: 'Failed',
    selected: false,
  },
]

const pricingFilterData = [
  {
    title: 'Same',
    selected: false,
  },
  {
    title: 'Changed',
    selected: false,
  },
  {
    title: 'Removed',
    selected: false,
  },
]

const invFilterData = [
  {
    title: 'Same',
    selected: false,
  },
  {
    title: 'Changed',
    selected: false,
  },
  {
    title: 'Removed',
    selected: false,
  },
]

const defaultDateFilters = {
  pricingDate: {
    from: undefined,
    to: undefined,
  },
  invDate: {
    from: undefined,
    to: undefined,
  },
}

type SearchFilterType = {
  storeIds?: number[]
  pSize?: number | undefined
  pNumber?: number | undefined
  text?: string | undefined
  priceDateFrom?: Date | undefined
  priceDateTo?: Date | undefined
  invDateFrom?: Date | undefined
  invDateTo?: Date | undefined
  fromDate?: string | undefined
  priceInfo?: string[] | undefined
  invInfo?: string[] | undefined
  statuses?: string[] | undefined
}

const ProductList: FC<Props> = () => {
  const storeOptions = useSelector(activeStoreSelector)
  const [totalCount, setTotalCount] = useState<number>(30)
  const [pagingData, setPagingData] = useState<PagingType>({ pageNumber: 0, pageSize: 50 })
  const [selectedStore, setSelectedStore] = useState<StoreType>(storeOptions[0])
  const [searchText, setSearchText] = useState<string>('')
  const [selectedRow, setSelectedRow] = useState<Product | null>()
  const [statusFilter, setStatusFilter] = useState<FilterData[]>(statusFilterData)
  const [pricingFilter, setPricingFilter] = useState<FilterData[]>(pricingFilterData)
  const [invFilter, setInvFilter] = useState<FilterData[]>(invFilterData)
  const [dateFilters, setDateFilters] = useState<DateFilters>(defaultDateFilters)
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<Product[]>([])
  const titleTimerRef = useRef<any>()
  const history = useHistory<{ store: StoreType }>()
  const header = 'Products'
  const info = 'In this page use will be able to see products pushed to the system from chosen shop'

  useEffect(() => {
    const { state } = history?.location
    if (state?.store) {
      setSelectedStore(state?.store)
      getProducts({ storeIds: [state?.store?.storeId] })
    } else {
      getProducts()
    }
  }, [])

  async function getProducts(searchFilter?: SearchFilterType) {
    let pricingFrom = dateFilters.pricingDate.from
    let invFrom = dateFilters.invDate.from
    let pricingTo = dateFilters.pricingDate.to
    let invTo = dateFilters.invDate.to
    if (searchFilter?.fromDate === 'pricingDate') {
      pricingFrom = searchFilter?.priceDateFrom
      pricingTo = searchFilter?.priceDateTo
    }

    if (searchFilter?.fromDate === 'invDate') {
      invFrom = searchFilter?.invDateFrom
      invTo = searchFilter?.invDateTo
    }
    try {
      setLoading(true)
      const { data, page, pageSize, totalCount } = await productApis.getPaging({
        page: searchFilter?.pNumber ?? pagingData.pageNumber,
        pageSize: searchFilter?.pSize ?? pagingData.pageSize,
        storeIds: searchFilter?.storeIds ?? [selectedStore.storeId],
        title: searchFilter?.text ?? searchText,
        priceUpdateDateFrom: pricingFrom,
        priceUpdateDateTo: pricingTo,
        inventoryUpdateDateFrom: invFrom,
        inventoryUpdateDateTo: invTo,
        priceUpdateInfo:
          searchFilter?.priceInfo ??
          pricingFilter?.filter((item) => item.selected).map((item) => item.title),
        inventoryUpdateInfo:
          searchFilter?.invInfo ??
          invFilter?.filter((item) => item.selected).map((item) => item.title),
        statuses:
          searchFilter?.statuses ??
          statusFilter?.filter((item) => item.selected).map((item) => item.title),
      })
      setData(data)
      setTotalCount(totalCount)
    } catch (er) {
      console.log('error', er)
    } finally {
      setLoading(false)
    }
  }

  function _onPageSizeChange(pageSize: number) {
    setPagingData((prev) => ({ ...prev, pageSize, pageNumber: 0 }))
    getProducts({ pSize: pageSize, pNumber: 0 })
  }

  function _onPageChange({ selected }: any) {
    setPagingData((prev) => ({ ...prev, pageNumber: selected }))
    getProducts({ pNumber: selected })
  }

  function _onChangeSelectedRow(product: Product) {
    setSelectedRow(product)
  }

  function _onChangeDate(dates: any, name: string) {
    const [start, end] = dates
    setDateFilters((prev) => ({
      ...prev,
      [name]: { from: start, to: end },
    }))

    setPagingData((prev) => ({ ...prev, pageNumber: 0 }))
    if (name === 'pricingDate') {
      getProducts({ pNumber: 0, priceDateFrom: start, priceDateTo: end, fromDate: name })
    } else if (name === 'invDate') {
      getProducts({ pNumber: 0, invDateFrom: start, invDateTo: end, fromDate: name })
    }
  }

  function checkSelected(filterData: FilterData[], index: number) {
    const response = filterData?.map((item, i) => {
      return { ...item, selected: i === index ? !item?.selected : item?.selected }
    })
    let selected: string[] = response
      ?.filter((item, index) => item?.selected)
      .map((item, index) => item?.title)
    return { selected, response }
  }

  function _onChangeFilter(name: string, index: number) {
    if (name === 'status') {
      const { response, selected } = checkSelected(statusFilter, index)
      setStatusFilter((prev) => response)
      getProducts({ pNumber: 0, statuses: selected })
    }
    if (name === 'pricing') {
      const { response, selected } = checkSelected(pricingFilter, index)
      setPricingFilter((prev) => response)
      getProducts({ pNumber: 0, priceInfo: selected })
    }
    if (name === 'inv') {
      const { response, selected } = checkSelected(invFilter, index)
      setInvFilter((prev) => response)
      getProducts({ pNumber: 0, invInfo: selected })
    }
  }

  function _onChangeSearchText(text: string) {
    setSearchText(text)
    if (titleTimerRef.current) {
      clearTimeout(titleTimerRef.current)
    }
    titleTimerRef.current = setTimeout(() => {
      setPagingData((prev) => ({ ...prev, pageNumber: 0 }))
      getProducts({ pNumber: 0, text })
    }, 1000)
  }

  function _selectStore(option: StoreType) {
    setSelectedStore(option)
    getProducts({ pNumber: 0, storeIds: [option?.storeId] })
  }

  function _renderTableData(data: Product[]) {
    return data
  }

  return (
    <Container>
      <SelectContainer>
        <label className='form-label fw-bold'>Select Store</label>
        <Select
          name='layout-builder[layout][header][width]'
          value={selectedStore}
          onChange={(option) => _selectStore(option)}
          options={storeOptions}
          withRs
        />
      </SelectContainer>
      <div className='card mb-5 mb-xl-8 paging-table-wrapper pb-5'>
        <ProductTable
          data={_renderTableData(data)}
          header={header}
          info={info}
          manageFilters={_onChangeFilter}
          manageSearchText={_onChangeSearchText}
          manageDates={_onChangeDate}
          searchText={searchText}
          statusFilterData={statusFilter}
          pricingFilterData={pricingFilter}
          invFilterData={invFilter}
          dateFilters={dateFilters}
          manageSelectedRow={_onChangeSelectedRow}
          loading={loading}
        />
        <div className='px-5 pb-5'>
          <Pagination2
            currentPage={pagingData?.pageNumber}
            pageSize={pagingData?.pageSize}
            totalCount={totalCount}
            onPageChange={_onPageChange}
            withPageSizeSelect
            onPageSizeChange={_onPageSizeChange}
          />
        </div>
      </div>
    </Container>
  )
}

export default ProductList

const Container = styled.div`
  min-width: 1300px;
  overflow: auto;
  margin-bottom:24px;
  .paging-table-wrapper {
    border-radius: 8px;
    box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  }
`