import { FC, useEffect, useRef, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import { CustomHeader, CustomLoading } from '../../components/CustomStyledComponents'
import { SelectContainer } from '../../layger/stores/store-settings/StoreSettings'
import { brandApis } from '../../../setup/apis/brand/brandApis'
import { PagingType } from '../../../setup/types/request-data-types/RequestDataTypes'
import Select from '../../components/Select'
import { pageSizeOptions, Pagination } from '../../components/Pagination'

type Props = {}

export type BrandType = {
  name: string
  disqualifiedCount: number
  evaluationStatus: string
  executed: boolean
  itemCount: number
  qualifiedCount: number
  runId: number
  categoryName: string
  productCount?: number
}

const BrandList: FC<Props> = () => {
  const [searchText, setSearchText] = useState<string>('')
  const [data, setData] = useState<BrandType[]>([])
  const [pagingData, setPagingData] = useState<PagingType>({ pageNumber: 0, pageSize: 50 })
  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>()
  const timerRef = useRef<any>(null)

  useEffect(() => {
    _getBrands()
  }, [])

  async function _getBrands(pSize?: number, pNumber?: number, search?: string) {
    setLoading(true)
    try {
      const { data, totalCount }: any = await brandApis.getPaging(
        {
          ...pagingData,
          pageSize: pSize ?? pagingData.pageSize,
          pageNumber: pNumber ?? pagingData.pageNumber,
        },
        search ?? searchText
      )
      setData(data || [])
      setTotalCount(totalCount)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  function _renderBrands(brands: BrandType[]) {
    return brands?.map((brand: BrandType, i: number) => (
      <div className='brand-container' key={i}>
        <div className='brand-container-left'>
          <div className='brand-num-of-items'>
            <span>{brand.itemCount}</span>
          </div>
          <div className='brand-title'>
            <span>{brand.name}</span>
          </div>
        </div>
        <div className='brand-container-right'>
          {[brand?.categoryName].map((cat: string, idx: number) => (
            <div className='brand-cat' key={idx}>
              <span>{cat}</span>
            </div>
          ))}
        </div>
      </div>
    ))
  }

  function _onPageSizeChanged(pageSize: number) {
    setPagingData((prev) => ({ ...prev, pageSize, pageNumber: 0 }))
    _getBrands(pageSize, 0)
  }

  function _onPageChange({ selected }: any) {
    setPagingData((prev) => ({ ...prev, pageNumber: selected }))
    _getBrands(undefined, selected)
  }

  function onSearch(search: string) {
    setSearchText(search)
    setPagingData((prev) => ({ ...prev, pageNumber: 0 }))
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      _getBrands(undefined, 0, search)
    }, 1000)
  }

  return (
    <Container className='card'>
      <CustomHeader>Brand List</CustomHeader>
      <hr className='m-0' />
      <div className='card-body p-0'>
        <div className='info-text'>
          <span className='info'>
            Here could be the short and sweet explanation of the section.
          </span>
        </div>
        <div className='search-container'>
          <SelectContainer className='store-select'>
            <label className='form-label fw-bold'>Search for Brands</label>
            <div className='icon-parent'>
              <input
                className='brand-search'
                type='text'
                placeholder='Insert a brand title'
                value={searchText}
                onChange={({ target }) => onSearch(target.value)}
              />
              <div className='icon'>
                <FaSearch />
              </div>
            </div>
          </SelectContainer>
          <div className='page-size-select'>
            <label className='form-label fw-bold'>Select Page Size</label>
            <Select
              name='layout-builder[layout][header][width]'
              value={pageSizeOptions.find((option) => option.value === pagingData.pageSize)}
              onChange={(option) => _onPageSizeChanged(option.value)}
              options={pageSizeOptions}
              withRs
            />
          </div>
        </div>
        <div className='body-container'>
          {!loading && data?.length ? (
            <div className='brands-container'>{_renderBrands(data)}</div>
          ) : null}
          {loading ? (
            <CustomLoading>
              <div className='text'>
                <span>Loading...</span>
              </div>
            </CustomLoading>
          ) : null}
          {!loading && !data?.length ? (
            <CustomLoading>
              <div className='text'>
                <span>No data could be fetched...</span>
              </div>
            </CustomLoading>
          ) : null}
        </div>
        <div className='px-8 py-5'>
          <Pagination
            currentPage={pagingData.pageNumber}
            pageSize={pagingData.pageSize}
            totalCount={totalCount}
            onPageChange={_onPageChange}
            onPageSizeChange={() => { }}
          />
        </div>
      </div>
    </Container>
  )
}

export default BrandList

const Container = styled.div`
  min-width: 456px;
  overflow: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
  margin-bottom: 48px;

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  .store-select {
    margin: 24px;
  }

  .page-size-select {
    width: 130px;
    margin: 24px 72px 24px 24px;
  }

  .info-text {
    margin: 24px 0 0 24px;
  }

  .info {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 500;
  }

  .body-container {
    width: 95%;
    height: 50vh;
    overflow: auto;
    margin-bottom: 24px;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #40b6f9;
    }

    scrollbar-width: 6px;
    scrollbar-height: 6px;
    scrollbar-color: #40b6f9 #e4e6ef;
  }

  .brand-search {
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #cdcfda;
    padding: 12px;
    outline: none;
    color: #a1a5bd;
    text-indent: 25px;
    width: 100%;
    color: #3f4254;
  }

  .brand-search:focus {
    box-shadow: 0 0 1px 3px #c0e7fd;
  }

  .brands-container {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    padding-inline: 8px;
  }

  .brand-container {
    box-sizing: border-box;
    margin: 16px;
    padding: 12px;
    min-height: 90px;
    border-radius: 13px;
    border: 2px solid #e1e6ed;
    box-shadow: 0px 0px 10px 3px rgba(120, 146, 165, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
  }

  .brand-container-left {
    display: flex;
    flex-direction: column;
  }

  .brand-num-of-items {
    color: #a1a5bd;
    font-size: 14px;
  }

  .brand-title {
    color: #07193a;
    font-size: 22px;
    font-weight: 700;
  }

  .brand-container-right {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    max-height: 60px;
    overflow: auto;
    padding-inline: 12px;

    //-------------scrollbar------------

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: #e4e6ef;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #40b6f9;
    }

    scrollbar-width: 4px;
    scrollbar-color: #40b6f9 #e4e6ef;
  }

  .brand-cat {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #adb0c5;
    font-size: 12px;
  }

  .icon-parent {
    position: relative;
    width: 100%;
    display: flex;
  }

  .icon {
    position: absolute;
    top: 16px;
    left: 16px;
  }
`
